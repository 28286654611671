import './SurveyResult.scss';
import {useEffect, useState} from "react";
import {SurveyService} from "../../../data/survey/Survey.service";
import {SurveyResult as SurveyResultModel} from "../../../data/survey/Survey";
import SurveyResultQuestion from "./SurveyResultQuestion";
import Spinner from "../../../ui/spinner/Spinner";
import {Carousel} from "react-bootstrap";
import surveyResultQuestion from "./SurveyResultQuestion";


export interface SurveyResultProps {
    surveyId: string;
    companyId: string;
}

const SurveyResult = (props: SurveyResultProps) => {

    const [result, setResult] = useState<SurveyResultModel | null>();
    const [indexSelected, setSelectedIndex] = useState(0);

    useEffect(() => {
        SurveyService.readResults(props.companyId, props.surveyId)
            .then(setResult)
    }, [])

    function selectSlice(eventKey: number, event: Object | null) {
        setSelectedIndex(eventKey)
    }

    const showNavigation = result && result.questionsResults.length > 1;

    return (
        <>
            {!result && <Spinner/>}
            {result &&
                <Carousel className='me-survey-result-carousel'
                          onSelect={selectSlice}
                          controls={!!showNavigation}
                          indicators={!!showNavigation}
                          interval={null} variant="dark">
                    {
                        result.questionsResults.map((resultQuestion, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <SurveyResultQuestion
                                        resultQuestion={resultQuestion}
                                        selected={indexSelected === index}
                                    />
                                </Carousel.Item>
                            )
                        })}
                </Carousel>}
        </>
    )

}

export default SurveyResult;