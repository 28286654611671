import './Welcome.scss';
import {Button, Modal} from "react-bootstrap";
import React from "react";


export interface WelcomeMessageProps {
    showModal: boolean;
    onCancel: () => void;
}

const WelcomeMessage = (props: WelcomeMessageProps) => {


    function cancel() {
        props.onCancel();
    }


    function createWelcomeMessage() {
        return <>
            <div>
                Wir freuen uns, dass du Teil unseres Netzwerks bist. <br/>
                Gerne möchten wir uns kurz bei dir vorstellen:
                Als junges Start-Up wollen wir lokale Unternehmen dabei unterstützen, ihre Produkte und
                Dienstleistungen in die digitale Welt zu bringen.
                <br/><br/>
                <b>Gemeinsam wachsen</b>
                <br/>
                Da wir ein kleines Start-up sind, ist sicher noch nicht alles perfekt. Aber wir arbeiten daran.
                Hast du Verbesserungswünsche für die Me-Plattform oder fehlt dir eine Funktion?
                Dann nimm gerne Kontakt mit uns auf, und wir werden versuchen, deine Anregungen umzusetzen.
                <br/><br/>
                Schreibe uns eine E-Mail oder klicke auf das Fragezeichensymbol unten rechts.
                <br/><br/>
                Kontakt: <a href="mailto: info@onra.ch">info@onra.ch</a>
                <br/><br/>
                Wir wünschen dir viel Freude mit Me.
                <br/>
                Dein onra-Team
            </div>
        </>
    }

    return <>
        <Modal show={props.showModal}
               size={"lg"}
               backdrop="static"
               onHide={cancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Herzlich willkommen bei Me!</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createWelcomeMessage()}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancel}> Let's go! </Button>
            </Modal.Footer>
        </Modal>
    </>

}

export default WelcomeMessage;