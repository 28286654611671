import axiosInstance from "../../AxiosInterceptor";
import {GemeindeAbout, GemeindeAboutItem} from "./GemeindeAbout";

export class GemeindeAboutService {

    public static async search(gemeindeId: string): Promise<GemeindeAbout | null> {
        const response = await axiosInstance.get(`gemeinden/${gemeindeId}/about/`);
        return this.mapAbout(response.data);
    }

    public static async update(gemeindeId: string, about: GemeindeAbout): Promise<void> {
        return await axiosInstance.post(`/gemeinden/${gemeindeId}/about`, about);
    }

    private static mapAbout(data: any): GemeindeAbout | null {
        if (!data) {
            return null;
        }
        return {
            gemeindeId: data.gemeindeId,
            description: data.description,
            items: data.items.map(this.mapAboutItems)
        }
    }

    private static mapAboutItems(data: any): GemeindeAboutItem {
        return {
            id: data.id,
            image: data.image,
            title: data.title,
            description: data.description
        }
    }


}