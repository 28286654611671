import './SurveyResultAnswer.scss';
import {SurveyResultAnswer as SurveyResultAnswerModel} from "../../../data/survey/Survey";
import {motion} from "framer-motion"

export interface SurveyResultAnswerProps {
    resultAnswer: SurveyResultAnswerModel;
    highestValue: number;
}

const SurveyResultAnswer = (props: SurveyResultAnswerProps) => {

    const resultAnswer = props.resultAnswer;
    const widthCalculated = (100 / props.highestValue) * props.resultAnswer.count;
    const isHighest = props.highestValue === resultAnswer.count;

    return (
        <div className='me-survey-result-answer'>
            <div className='me-survey-result-answer-text-placeholder'>{resultAnswer.text}</div>
            <div className={`me-survey-result-answer-text ${isHighest? 'highest' : ''}`}>{resultAnswer.text}</div>
            <motion.div
                initial={{opacity: 0, width: 0}}
                animate={{opacity: 1, width: widthCalculated + '%'}}
                transition={{duration: 2}}
                className='me-survey-result-answer-marker'>
            </motion.div>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 1, delay: 2}}
                className='me-survey-result-answer-text-percent'>
                {resultAnswer.percent+'%'}
            </motion.div>
        </div>
    )

}

export default SurveyResultAnswer;