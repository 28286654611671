export class StringUtil {

    static removeWhiteSpaces(text: string) {
        return text.replace(' ', '');
    }

    static removeNonNumerics(text: string) {
        return text.replace(/[^\d]/g, '');
    }

    static hasNonNumerics(text: string) {
        return text.match(/[^\d]/g);
    }

    static cut(text: string, length: number) {
        if (!text || text.length < length) {
            return text;
        }
        return text.substring(0, length - 3) + '...';
    }
}