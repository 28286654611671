import "./OpeningHours.scss";

import {Alert, Button, Col, Modal, Row} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import Form from "react-bootstrap/Form";
import OpeningHoursEditForm from "./OpeningHoursEdit/OpeningHoursEdit";
import {OpeningHour, OpeningHoursState, WeekDays} from "../../../../data/company/OpeningHour";
import IconButton from "../../../../ui/icon-button/IconButton";
import {CompanyService} from "../../../../data/company/Company.service";
import {companyActions} from "../../../../store/company";

export interface OpeningHoursSettingsProps {
    showModal: boolean;
    onSave: () => void;
    onCancel: () => void;
}

const OpeningHoursSettingsForm = (props: OpeningHoursSettingsProps) => {

    const dispatch = useDispatch();
    const [errorText, setErrorText] = useState('');
    const company = useSelector((state: RootState) => state.company.data);
    const companyId = company ? company.id : '';
    const [isSaving, setSaving] = useState(false);
    const [modalEditOpeningHoursOpen, setModalEditOpeningHoursOpen] = useState(false);
    const [editedWeekday, setEditedWeekday] = useState(WeekDays.MONDAY);
    const [openingHours, setOpeningHours] = useState(company?.openingHours);
    const [openingHoursStateMessage, setOpeningHoursStateMessage] = useState(company?.openingHoursState.message);

    function resetForm() {
        setOpeningHours(company?.openingHours);
        setOpeningHoursStateMessage(company?.openingHoursState.message);
        setErrorText('');
    }

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function save() {
        setSaving(true);
        const openingHoursState: OpeningHoursState = {message: openingHoursStateMessage as string}
        CompanyService.updateOpeningHours(openingHours as any, openingHoursState)
            .then(() => {
                CompanyService.read(companyId).then(updatedCompany => {
                    dispatch(companyActions.setCompany(updatedCompany));
                    setSaving(false);
                    props.onSave();
                })
            })
            .catch(error => {
                setSaving(false);
                setErrorText(error);
            });
    }

    function editDay(day: WeekDays) {
        setEditedWeekday(day);
        setModalEditOpeningHoursOpen(true);
    }

    function getOpeningHourTextForDay(day: WeekDays): any[] {
        const allOpeningHours: any[] = [];
        if (openingHours) {
            const foundOpeningHours = openingHours
                .filter(openingHour => openingHour.weekday === day);
            if (foundOpeningHours.length === 0) {
                allOpeningHours.push(<div key={day}>-</div>)
                return allOpeningHours;
            }
            foundOpeningHours.forEach(found => {
                allOpeningHours.push(<div key={day}>{found.from} - {found.to}</div>);
            })
        }
        return allOpeningHours;
    }

    function onOpenHoursStateMessage(event: any) {
        setOpeningHoursStateMessage(event.target.value);
    }

    function onDelete(day: WeekDays) {
        if (openingHours) {
            const filteredOpeningHours = openingHours
                .filter(openingHour => openingHour.weekday !== day);
            setOpeningHours(filteredOpeningHours);
        }
    }

    const WeekDayRow = (props: any) => {
        return (
            <Row className='mb-3'>
                <Col>
                    <Form.Label>{props.label}</Form.Label>
                </Col>
                <Col>
                    {getOpeningHourTextForDay(props.weekDay)}
                </Col>
                <Col>
                    <div className={'me-flex-row'}>
                        <IconButton
                            iconName='edit'
                            onClick={() => editDay(props.weekDay)}/>
                        <IconButton
                            iconName={'trash-can'} marginLeft={10} className={'red-hover'}
                            onClick={() => onDelete(props.weekDay)}/>
                    </div>
                </Col>
            </Row>
        )
    }

    function createForm() {
        return <div className={'me-flex-column me-flex-grow'}>
            <div className={'me-openhours-state'}>
                <Form.Control
                    id='message'
                    maxLength={50}
                    placeholder={"Meldung anstelle Öffnungszeiten ..."}
                    onChange={onOpenHoursStateMessage}
                    value={openingHoursStateMessage}
                    required>
                </Form.Control>
            </div>
            <Form className={'me-opening-hours-body'}>
                <WeekDayRow label={'Montag'} weekDay={WeekDays.MONDAY}/>
                <WeekDayRow label={'Dienstag'} weekDay={WeekDays.TUESDAY}/>
                <WeekDayRow label={'Mittwoch'} weekDay={WeekDays.WEDNESDAY}/>
                <WeekDayRow label={'Donnerstag'} weekDay={WeekDays.THURSDAY}/>
                <WeekDayRow label={'Freitag'} weekDay={WeekDays.FRIDAY}/>
                <WeekDayRow label={'Samstag'} weekDay={WeekDays.SATURDAY}/>
                <WeekDayRow label={'Sonntag'} weekDay={WeekDays.SUNDAY}/>
            </Form>

            {modalEditOpeningHoursOpen && openingHours &&
                <OpeningHoursEditForm
                    dayOfTheWeek={editedWeekday}
                    openingHours={openingHours}
                    showModal={modalEditOpeningHoursOpen}
                    onSave={(openingHours: OpeningHour[]) => {
                        setOpeningHours(openingHours);
                        setModalEditOpeningHoursOpen(false);
                    }}
                    onCancel={() => setModalEditOpeningHoursOpen(false)}
                />
            }
            {errorText && <Alert className='settings-error' variant='danger'>{errorText}</Alert>}
        </div>
    }

    return <>
        <Modal show={props.showModal}
               backdrop="static"
               onHide={cancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Öffnungszeiten</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createForm()}</Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    </>

}

export default OpeningHoursSettingsForm;