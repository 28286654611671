import './ProfilMenuButton.scss';

import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";


export interface AccountMenuButtonProps {
    onClick: () => void;
    icon?: IconProp;
    submenu?: boolean;
    label?: string;
}

const ProfilMenuButton = (props: AccountMenuButtonProps) => {


    return (
        <Button
            onClick={props.onClick}
            variant={"outline-secondary"}
            className={'me-account-menu-button'}>
            <div className={'me-account-menu-button-icon-container'}>
                {props.icon && <FontAwesomeIcon icon={props.icon}/>}
            </div>
            <div className={`me-account-menu-button-label ${props.submenu ? 'submenu' : ''}`}>{props.label}</div>
        </Button>
    )

}

export default ProfilMenuButton;