import './EditCompanyTeamImagePopup.scss';

import {Alert, Button, Form} from "react-bootstrap";
import ImageSelector from "../../../../ui/image-selector/ImageSelector";
import React, {useEffect, useState} from "react";
import {CompanyTeamImage} from "../../../../data/company/team/CompanyTeam";

export interface EditCompanyTeamImageProps {
    editTeamImage?: CompanyTeamImage | null;
    close: () => void;
    addTeamImage: (teamImage: CompanyTeamImage) => void;
}

const EditCompanyTeamImagePopup = (props: EditCompanyTeamImageProps) => {

    const [error, setError] = useState('');
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        if (props.editTeamImage) {
            setTitle(props.editTeamImage.title);
            setDescription(props.editTeamImage.description);
            setImage(props.editTeamImage.image);
        }
    }, [props.editTeamImage])

    function onTitleChanged(event: any) {
        setError('');
        setTitle(event.target.value)
    }

    function onDescriptionChanged(event: any) {
        setError('');
        setDescription(event.target.value)
    }

    function onImageChanged(image: string) {
        setError('');
        setImage(image);
    }

    function save() {
        if (!image) {
            setError("Ein Bild ist erforderlich.");
            return;
        }
        const id = props.editTeamImage ? props.editTeamImage.id : new Date().getTime().toString(36);
        props.addTeamImage({
            id: id,
            title: title,
            description: description,
            image: image
        })
    }

    return (
        <>
            <div className={'me-company-edit-team-image-backdrop'}/>
            <div className={'me-company-edit-team-image-popup'}>
                <Form>
                    <Form.Group className="mb-3">
                        <ImageSelector
                            defaultImage={image}
                            imageMaxSizeMB={0.4}
                            imageSelected={onImageChanged}
                        />
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Control
                            placeholder={'Title'}
                            value={title}
                            maxLength={25}
                            onChange={onTitleChanged}
                            required>
                        </Form.Control>
                        <div className={'me-max-character-indicator'}>{title?.length}/25</div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            as="textarea" rows={4}
                            placeholder={'Beschreibung'}
                            value={description}
                            maxLength={500}
                            onChange={onDescriptionChanged}/>
                        <div className={'me-max-character-indicator'}>{description?.length}/500</div>
                    </Form.Group>
                </Form>
                {error && <Alert className={`me-add-company-image-alert`} variant='danger'>{error}</Alert>}
                <div className={'me-company-edit-team-image-popup-footer'}>
                    <Button variant="secondary" onClick={props.close}> Abbrechen </Button>
                    <Button variant="primary" onClick={save}> Übernehmen </Button>
                </div>
            </div>
        </>
    )

}

export default EditCompanyTeamImagePopup;