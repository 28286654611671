import './Label.scss';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface LabelProps {
    icon?: IconProp;
    label: string;
}

const Label = (props: LabelProps) => {


    return (
        <div className={'me-label-component'}>
            {props.icon && <FontAwesomeIcon className={'me-label-component-icon'} icon={props.icon}/>}
            <div className={'me-label-component-label'}>{props.label}</div>
        </div>
    )

};


export default Label;