import axiosInstance from "../../AxiosInterceptor";
import {Post,} from "./Post";

export class PostService {

    public static async add(ownerId: string, post: Post): Promise<void> {
        return await axiosInstance.post('posts/' + ownerId, post);
    }

    public static async update(postId: string, post: Post): Promise<void> {
        return await axiosInstance.put(`posts/${postId}`, post);
    }

    public static async search(ownerId: string): Promise<Post[]> {
        const response = await axiosInstance.get(`posts/owner/${ownerId}`);
        return response.data;
    }

    public static async delete(postId: string) {
        return await axiosInstance.delete(`posts/${postId}`);
    }

    public static async activate(postId: string) {
        return await axiosInstance.post(`posts/${postId}/activate`);
    }

}