import Company, {CompanyProfilUpdate, mapCompany} from "./Company";
import axiosInstance from "../../AxiosInterceptor";
import {SendFeedback} from "./Feedback";
import {CompanyProfileState} from "./CompanyProfileState";
import {OpeningHour, OpeningHoursState} from "./OpeningHour";
import {Address} from "../address/Address";
import {AddressLocation} from "../address/AddressLocation";

export class CompanyService {

    public static async read(companyId: string): Promise<Company> {
        const response = await axiosInstance.get('companies/' + companyId);
        return mapCompany(response.data);
    }

    public static async getCredits(companyId: string): Promise<any> {
        const response = await axiosInstance.get('companies/getCredits/' + companyId, {});
        return response.data;
    }

    public static async update(companyProfilUpdate: CompanyProfilUpdate): Promise<void> {
        return await axiosInstance.post('companies/profile/', companyProfilUpdate);
    }

    public static async sendFeedbackToOnra(companyFeedback: SendFeedback): Promise<void> {
        return await axiosInstance.post('/companies/sendFeedbackToOnra', companyFeedback)
    }

    public static async updateOpeningHours(openingHours: OpeningHour[], openingHoursState: OpeningHoursState) {
        return await axiosInstance.post('/companies/profile/openinghours', {
            openingHours: openingHours,
            openingHoursState: openingHoursState
        })
    }

    public static async updateProfileState(companyProfileState: CompanyProfileState) {
        return axiosInstance.post('/companies/profile/state', companyProfileState)
    }

    public static async updateAddress(companyId: string, updateAddress: {
        address: Address;
        locations: AddressLocation[]
    }) {
        return await axiosInstance.post('companies/' + companyId + '/address/', {updateAddress});
    }

}