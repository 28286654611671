import './dashboardCard.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {DetailInfo} from "../../../data/dashboard/DetailInfo";

export interface InfoCardProps {
    onClick?: () => void;
    title: string;
    value: string;
    iconName: any;
    selected: boolean;
    tourSelector: string;
    loading: boolean;
    detailInfos: DetailInfo[];
}

const DashboardCard = (props: InfoCardProps) => {

    function onClick() {
        if (props.onClick) {
            props.onClick();
        }
    }

    const iconName = props.iconName;

    return (
        <div id={props.tourSelector} className={`me-dashboard-card ${props.selected ? 'selected' : ''}`} onClick={onClick}>
            <div className='me-dashboard-card-header'>
                <FontAwesomeIcon className='me-dashboard-card-icon' icon={iconName}/>
                <div className='me-dashboard-card-title'>{props.title}</div>
            </div>
            {props.loading && <div className='me-dashboard-card-value loading'>{'?'}</div>}
            {!props.loading && <div className='me-dashboard-card-value'>{props.value}</div>}
            {!props.loading && props.detailInfos.map((info) => {
                return <div key={info.title} className={'minPad me-dashboard-card-detail-container'}>
                    {info.iconName && <FontAwesomeIcon icon={info.iconName}/>}
                    <div className='me-dashboard-card-detail-text'>{info.title}: {info.count}</div>
                </div>
            })}
        </div>
    )

}

export default DashboardCard;