import './CreateSurveyForm.scss';

import {Alert, Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {DateUtil} from "../../../data/DateUtil";
import {AddSurvey, Survey, SurveyQuestion, SurveyState} from "../../../data/survey/Survey";
import {SurveyService} from "../../../data/survey/Survey.service";
import Spinner from "../../../ui/spinner/Spinner";
import CreateSurveyQuestions from "./CreateSurveyQuestions";
import VerticalMenu, {VerticalMenuItem} from "../../../ui/menu/VerticalMenu";
import SurveyPreview from "../preview/SurveyPreview";
import VisibilityToggler from "../../base/visibilty/visibility-toggler";
import {Visibility} from "../../../data/base/Visibility";
import InvitationList from "../../event/invitation-list/InvitationList";

export interface CreateSurveyFormProps {
    showModal: boolean;
    intialSurvey?: Survey;
    onSave: () => void;
    onCancel: () => void;
}

const CreateSurveyForm = (props: CreateSurveyFormProps) => {
    const [error, setError] = useState('');
    const [validated, setValidated] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [isSavingActive, setSavingActive] = useState(true);
    const formRef = useRef(null);

    const [selectedMenu, setSelectedMenu] = useState('INTRO');
    const [enddate, setEnddate] = useState('');
    const [introTitle, setIntroTitle] = useState('');
    const [introDescription, setIntroDescription] = useState('');
    const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
    const [confirmTitle, setConfirmTitle] = useState('');
    const [visibility, setVisibility] = useState(Visibility.PUBLIC);
    const [invitationClientIds, setInvitationClientIds] = useState<string[]>([])

    const menuItems: VerticalMenuItem[] = [
        {code: 'INTRO', label: 'Details', disabled: false},
        {code: 'PARTICIPANTS', label: 'Einladungsliste', disabled: visibility !== Visibility.LIST},
        {code: 'QUESTIONS', label: 'Fragen', disabled: false},
        {code: 'CONFIRM', label: 'Abschluss', disabled: false},
        {code: 'PREVIEW', label: 'Vorschau', disabled: false}
    ]

    useEffect(() => {
        if (props.showModal) {
            if (props.intialSurvey) {
                mapInitialQuestion(props.intialSurvey);
            }
        }
    }, [props.showModal])

    function mapInitialQuestion(initial: Survey) {
        setEnddate(DateUtil.asFormDateString(initial.enddate))
        const questions: SurveyQuestion[] = initial.questions.map(q => {
            const answers = q.answers.map(a => {
                return {
                    text: a.text
                }
            });
            return {
                question: q.question,
                answers: answers
            };
        });
        setQuestions(questions);
        setIntroTitle(initial.intro.title);
        setIntroDescription(initial.intro.description);
        setConfirmTitle(initial.confirm.title);
        setVisibility(initial.visibility);
        setInvitationClientIds([...initial.invitationList]);
    }

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function saved() {
        resetForm();
        props.onSave();
    }

    function resetForm() {
        setSelectedMenu('INTRO');
        setError(() => '')
        setSaving(() => false);
        setSavingActive(true);
        setValidated(() => false)
        setEnddate('');
        setIntroTitle('');
        setIntroDescription('');
        setQuestions([]);
        setConfirmTitle('');
        setInvitationClientIds(() => []);
        setVisibility(Visibility.PUBLIC);
    }

    function onIntroTitleChanged(event: any) {
        setError('');
        setIntroTitle(event.target.value);
    }

    function onIntroDescriptionChanged(event: any) {
        setError('');
        setIntroDescription(event.target.value);
    }

    function onEnddateChanged(event: any) {
        setError('');
        setEnddate(event.target.value);
    }

    function onConfirmTitleChanged(event: any) {
        setError('');
        setConfirmTitle(event.target.value);
    }

    function onVisibilityChanged(visibility: Visibility) {
        setError('');
        if (visibility !== Visibility.LIST) {
            setInvitationClientIds([]);
        }
        setVisibility(visibility);
    }

    function save() {
        const form = formRef.current as any;
        setError('');
        setValidated(true);
        if (!isValid()) {
            return;
        }
        if (form.checkValidity() === true) {
            setSaving(true);
            if (props.intialSurvey) {
                SurveyService.update(createUpdateSurvey())
                    .then(saved)
                    .catch(() => setSaving(false));
            } else {
                SurveyService.add(createAddSurvey())
                    .then(saved)
                    .catch(() => setSaving(false));
            }
        }
    }

    function isValid(): boolean {
        if (!introTitle || !introDescription) {
            setError("Einleitung erforderlich");
            return false;
        }
        if (!questions || questions.length === 0) {
            setError('Eine Frage ist erforderlich');
            return false;
        }
        if (questions.some(q => q.answers.length < 2)) {
            setError('Eine Frage benötigt immer mindestens zwei Antworten');
            return false;
        }
        if (!confirmTitle) {
            setError("Abschluss erforderlich");
            return false;
        }
        return true;
    }

    function createUpdateSurvey(): Survey {
        return {
            companyId: props.intialSurvey?.companyId as string,
            state: props.intialSurvey?.state as SurveyState,
            surveyId: props.intialSurvey?.surveyId as string,
            visibility: visibility,
            invitationList: invitationClientIds,
            enddate: enddate,
            intro: {
                title: introTitle,
                description: introDescription
            },
            confirm: {
                title: confirmTitle
            },
            questions: questions,
            created: props.intialSurvey?.created as string,
        }
    }

    function createAddSurvey(): AddSurvey {
        const enddateAsDate = DateUtil.dateWithoutTime(enddate)
        return {
            visibility: visibility,
            invitationList: invitationClientIds,
            enddate: enddateAsDate,
            intro: {
                title: introTitle,
                description: introDescription
            },
            confirm: {
                title: confirmTitle
            },
            questions: questions,
        }
    }

    function createForm() {
        return (
            <>
                <Form ref={formRef} id='createSurveyForm'
                      noValidate
                      className='me-create-survey-form'
                      validated={validated}>
                    <VerticalMenu items={menuItems} onSelect={setSelectedMenu}>
                        {selectedMenu === 'INTRO' &&
                            <div className='me-flex-grow'>
                                <Form.Group className='mb-3'>
                                    <Form.Control
                                        required
                                        placeholder={'Titel*'}
                                        value={introTitle}
                                        onChange={onIntroTitleChanged}/>
                                </Form.Group>
                                <Form.Group className={'me-flex-column me-flex-grow'}>
                                    <Form.Control
                                        className={'me-survey-description-input'}
                                        as="textarea"
                                        required
                                        value={introDescription}
                                        maxLength={200}
                                        rows={3}
                                        placeholder={'Beschreibung: Worum geht es in deiner Umfrage?*'}
                                        onChange={onIntroDescriptionChanged}/>
                                </Form.Group>
                                <Form.Label
                                    className={'me-survey-description-count mb-3'}>{introDescription.length}/200</Form.Label>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Umfragezeitraum bis und mit*</Form.Label>
                                    <Form.Control
                                        required type="date"
                                        value={enddate}
                                        onChange={onEnddateChanged}
                                    />
                                </Form.Group>
                                <Form.Group className="mt-3 mb-3">
                                    <Form.Label>Bitte wähle das Zielpublikum für deine Umfrage aus.</Form.Label>
                                    <VisibilityToggler
                                        initialValue={visibility}
                                        onChange={onVisibilityChanged}
                                    />
                                </Form.Group>

                            </div>
                        }
                        {selectedMenu === 'PARTICIPANTS' &&
                            <InvitationList
                                initialInvitationClientIds={invitationClientIds}
                                invitationClientIdsChanged={setInvitationClientIds}
                            />}
                        {selectedMenu === 'QUESTIONS' &&
                            <div className='me-flex-column me-flex-grow'>
                                <CreateSurveyQuestions
                                    onIsEditing={isEditing => setSavingActive(!isEditing)}
                                    initialQuestions={questions}
                                    onQuestionsChanged={setQuestions}/>
                            </div>
                        }
                        {selectedMenu === 'CONFIRM' &&
                            <div className='me-flex-grow mt-3'>
                                <Form.Control
                                    required
                                    placeholder={'Danke für deine Teilnahme*'}
                                    value={confirmTitle}
                                    onChange={onConfirmTitleChanged}/>
                            </div>
                        }
                        {selectedMenu === 'PREVIEW' &&
                            <SurveyPreview survey={createUpdateSurvey()}/>
                        }
                    </VerticalMenu>
                </Form>
                <Alert className={`me-save-survey-alert ${error !== '' ? 'show-error' : ''}`}
                       variant='danger'>{error}</Alert>
            </>
        )
    }

    return (
        <Modal show={props.showModal}
               className='me-create-survey-modal'
               backdrop="static"
               onHide={cancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Umfrage erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createForm()}</Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner/>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving &&
                    <Button variant="primary" onClick={save} disabled={!isSavingActive}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    )

}

export default CreateSurveyForm;
