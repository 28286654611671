import './Tour.scss';
import React, {useState} from "react";
import Joyride from 'react-joyride';


export interface TourProps {
    onCancel: () => void;
}

const Tour = (props: TourProps) => {

    const [steps, setSteps] = useState([
        {
            target: '#me-header-company-settings',
            disableBeacon: true,
            content: getTourTextProfile()
        },
        {
            target: '#me-follower-card',
            disableBeacon: true,
            content: getTourTextFollowerCard(),
        },
        {
            target: '#me-mitteilungen-card',
            disableBeacon: true,
            content: getTourTextMitteilungCard(),
        },
        {
            target: '#me-events-card',
            disableBeacon: true,
            content: getTourTextEventCard(),
        },
        {
            target: '#me-chats-card',
            disableBeacon: true,
            content: getChatsTextTokens(),
        },
        {
            target: '#me-survey-card',
            disableBeacon: true,
            content: getTourTextSurvey(),
        },
        {
            target: '#me-header-question-icon',
            disableBeacon: true,
            content: getTourTextQuestionIcon(),
        }
    ]);


    function handleJoyrideCallback(data: any) {
        const {action, index, status, lifecycle} = data;
        if (status === "finished") {
            props.onCancel();
        }
    }

    return (
        <div className="app">
            <Joyride
                steps={steps}
                spotlightClicks={true}
                continuous={true}
                run={true}
                locale={{
                    back: 'zurück',
                    close: 'schliesse',
                    last: 'Tour beenden',
                    next: 'weiter',
                    open: 'öffnen',
                    skip: 'skip'
                }}
                callback={handleJoyrideCallback}
                styles={{
                    options: {
                        backgroundColor: 'rgb(239, 237, 255)',
                        primaryColor: '#0d6efd',
                        textColor: '#000',
                        zIndex: 1000
                    },
                    tooltipContainer: {
                        textAlign: 'left',
                    }
                }}
            />
        </div>
    );


}

export function getTourTextProfile() {
    return <>
        <div>Hier findest du dein persönliches Profil. Du kannst darin dein Logo, deine
            Kontaktdaten und deine Öffnungszeiten erfassen und jederzeit anpassen.
        </div>
    </>
}

export function getTourTextFollowerCard() {
    return <>
        <div>Hier siehst und verwaltest du alle Personen, die deinem Unternehmensprofil in der Me App folgen.<br/><br/>
            <b>Follower</b> sind alle Personen, die dir folgen, im Überblick. <br/><br/>
            <b>Kontakte</b> sind Personen, die du kennst und als Kontakt gekennzeichnet hast.
        </div>
    </>
}

export function getTourTextMitteilungCard() {
    return <>
        <div>Hast du Neuigkeiten? <br/>Ein tolles Sonderangebot?<br/> Oder willst du deinen Followern sonst etwas
            mitteilen?<br/><br/>
            Dann nutze dafür die Post-Funktion. Mit ihr kannst du ganz einfach Angebote oder andere Neuigkeiten erfassen
            und deinen Followern in der Me App präsentieren.
        </div>
    </>
}

export function getTourTextEventCard() {
    return <>
        <div>Gibt es etwas zu feiern? <br/>Oder organisierst du einen Anlass? <br/><br/>Dann nutze dafür die
            Event-Funktion.
            Mit ihr kannst du ganz einfach Events versenden. Deine Follower werden per Push-Nachricht
            in der Me App über die Einladung informiert. Sie können ihre Teilnahme anmelden oder via Chat Fragen mit dir
            klären.
        </div>
    </>
}

export function getTourTextSurvey() {
    return <>
        <div> Du hast eine Idee und hättest gerne Kundenfeedback? <br/>
            Du organisiertst einen Anlass und benötigst zur Planung noch Informationen? <br/>
            Oder du willst sonst eine Frage in die Runde werfen?<br/> <br/>
            Hier kannst du Umfragen erstellen und mit deinen Followern teilen.
        </div>
    </>
}

export function getChatsTextTokens() {
    return <>
        <div>
            Möchtest du mit deinen Followern chatten oder benötigst du ein Gefäß, in dem sich deine Follower austauschen
            können? <br/>
            Dann sind die Chats genau das Richtige! <br/>
            Erstelle spannende Unterhaltungen zu bestimmten Themen
            oder erhalte direkte Nachrichten von deinen Followern.
        </div>
    </>
}

export function getTourTextQuestionIcon() {
    return <>
        <div>Falls du weitere Hilfe benötigst oder uns ein Feedback geben möchtest, klicke bitte hier unten auf das
            Fragezeichen.
        </div>
    </>
}

export default Tour;