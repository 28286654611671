import './PasswordReset.scss';
import {useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {RouteNames} from "../../route-names";
import validator from "validator";
import {useDispatch} from "react-redux";

// @ts-ignore
import LoginScreenSVG from "../../images/login_screen.svg";
// @ts-ignore
import LogoAnimatedSVG from "../../images/logo_animated_converted.svg";
import {PasswordService} from "../../data/auth/Password.service";
import Spinner from "../../ui/spinner/Spinner";

const PasswordReset = () => {

    const dispatch = useDispatch();

    enum StepType {
        ENTER_EMAIL,
        ENTER_CODE,
        PASSWORD_CHANGE
    }

    // Fields
    const [email, setEmail] = useState('');
    const [emailBlur, setEmailBlur] = useState(false);
    const [emailValid, setEmailValid] = useState(false);

    const [step, setStep] = useState(StepType.ENTER_EMAIL);
    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordConfirmValid, setPasswordConfirmValid] = useState(false);
    const [code, setCode] = useState('');

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    function onPasswordChangeHandler(event: any) {
        const newValue = event.target.value;
        setPassword(newValue);
        setPasswordValid(newValue && newValue.length >= 8)
    }

    function onConfirmPasswordChangeHandler(event: any) {
        const newValue = event.target.value;
        setConfirmPassword(newValue);
        setPasswordConfirmValid(password === confirmPassword);
    }

    function onCodeChangeHandler(event: any) {
        const newValue = event.target.value;
        setCode(newValue);
    }

    function onEmailChangeHandler(event: any) {
        const newValue = event.target.value;
        setEmail(() => newValue);
        setEmailValid(validator.isEmail(newValue));
    }

    function onEmailBlur() {
        setEmailBlur(true);
        setEmailValid(validator.isEmail(email));
    }

    function onFormSubmitHandler(event: any) {
        event.preventDefault();
        handleStepChange();
    }

    function requestPasswordReset() {
        setLoading(true);
        PasswordService.requestReset(email).then((response) => {
            setLoading(false);
            if (response === PasswordService.EMAIL_SENT) {
                setStep(StepType.ENTER_CODE);
                return;
            }
            handleErrorResponse(response);
            return;
        });
    }

    function doPasswordReset() {
        if (!isValidForm()) {
            return;
        }
        setLoading(true);
        const resetPassowrd = {email: email, code: code, password: password};
        PasswordService.doReset(resetPassowrd).then((response) => {
            setLoading(false);
            if (response === PasswordService.PASSWORD_SUCCESSFUL_CHANGED) {
                setStep(StepType.PASSWORD_CHANGE);
                setTimeout(() => {
                    navigateLogin();
                }, 1000);
                return;
            }
            handleErrorResponse(response);
            return;
        });
    }

    function handleStepChange() {
        setError('');
        if (step === StepType.ENTER_EMAIL) {
            requestPasswordReset();
        }
        if (step === StepType.ENTER_CODE) {
            doPasswordReset();
        }
    }

    function handleErrorResponse(response: string) {
        if (response === PasswordService.REQUEST_FAILED_NO_CODE_FOUND) {
            setError('Leider ist Ihre Anfrage abgelaufen, starten Sie das zurücksetzen erneut');
        }
        if (response === PasswordService.REQUEST_FAILED_EMAIL_NOT_IN_USE) {
            setError('Wir haben für die angegeben E-Mail kein Konte gefunden.');
        }
        if (response === PasswordService.REQUEST_FAILED_CODE_NOT_EQUAL) {
            setError('Der angegebene Code stimmt nicht mit unserer Datenbank überein.');
        }
    }

    function isValidForm() {
        if (!code) {
            setError('Bitte Code eingeben');
            return false;
        }
        if (!password) {
            setError('Bitte Passwort eingeben');
            return false;
        }
        if (password.length < 8) {
            setError('Passwort soll mindestens 8 Zeichen haben');
            return false;
        }
        if (password !== confirmPassword) {
            setError('Passwort stimmt nicht überein');
            return false;
        }
        return emailValid && passwordValid;
    }

    function navigateLogin() {
        navigate('/' + RouteNames.LOGIN);
    }


    return (
        <>
            <div className='me-password-reset-container'>
                <img className='me-password-reset-background' src={LoginScreenSVG} alt={''}></img>
                <div className='me-password-reset-form-container'>
                    <img className='me-password-reset-logo' src={LogoAnimatedSVG} alt={''}></img>
                    <Form className='me-password-reset-form'
                          noValidate
                          onSubmit={onFormSubmitHandler}>
                        <Form.Group className="mb-3">
                            <Form.Label>E-Mail*</Form.Label>
                            <Form.Control
                                isValid={emailValid}
                                isInvalid={!emailValid && (emailBlur || formSubmitted)}
                                disabled={step !== StepType.ENTER_EMAIL}
                                onBlur={onEmailBlur}
                                onChange={onEmailChangeHandler}/>
                            <Form.Control.Feedback type="invalid">
                                Email ist ungültig.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {(step === StepType.ENTER_CODE || step === StepType.PASSWORD_CHANGE) &&
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Code*</Form.Label>
                                    <Form.Control
                                        onChange={onCodeChangeHandler}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Neues Passwort*</Form.Label>
                                    <Form.Control
                                        isValid={passwordValid}
                                        isInvalid={!passwordValid && formSubmitted}
                                        type="password"
                                        onChange={onPasswordChangeHandler}/>
                                    <Form.Control.Feedback type="invalid">
                                        Passwort muss min. 8 Zeichen lang sein.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Passwort bestätigen*</Form.Label>
                                    <Form.Control
                                        isValid={passwordConfirmValid}
                                        isInvalid={!passwordConfirmValid && formSubmitted}
                                        type="password"
                                        onChange={onConfirmPasswordChangeHandler}/>
                                    <Form.Control.Feedback type="invalid">
                                        Passwort muss min. 8 Zeichen lang sein.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>
                        }
                        {error && <Alert className='me-login-alert' variant='danger'> {error} </Alert>}
                        {loading && <Spinner></Spinner>}

                        {step === StepType.PASSWORD_CHANGE &&
                            <Alert className='me-login-alert' variant='success'>
                                Passwort erfolgreich geändert!
                            </Alert>
                        }
                        {step === StepType.ENTER_EMAIL &&
                            <Button className='me-password-reset-button' variant={'outline-secondary'}
                                    onClick={() => handleStepChange()}>Anfrage starten</Button>
                        }
                        {step === StepType.ENTER_CODE &&
                            <Button className='me-password-reset-button' variant={'outline-secondary'}
                                    onClick={() => handleStepChange()}>Passwort ändern</Button>
                        }

                    </Form>
                </div>
                <span className='me-password-reset-info'>
                    <div className='me-password-reset-info-text'>
                        <a className='me-password-reset-to-login-link' onClick={navigateLogin}>{'< Zurück zum Login'}</a>
                    </div>
                </span>
            </div>
        </>
    )

}


export default PasswordReset;