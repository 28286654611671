import {configureStore} from "@reduxjs/toolkit";

import settingReducer from './settings';
import confirmReducer from './confirm';
import eventsReducer from './events';
import postsReducer from './posts';
import subscriptionReducer from './subscriptions';
import surveysReducer from './surveys';
import chatsReducer from './chats';
import applicationReducer from './application';
import accountReducer from './account';
import paymentsReducer from './payments';
import companyReducer from './company';
import gemeindeReducer from './gemeinde';

const store = configureStore({
    reducer: {
        setting: settingReducer,
        confirm: confirmReducer,
        events: eventsReducer,
        posts: postsReducer,
        subscriptions: subscriptionReducer,
        surveys: surveysReducer,
        chats: chatsReducer,
        application: applicationReducer,
        account: accountReducer,
        company: companyReducer,
        gemeinde: gemeindeReducer,
        payments: paymentsReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export default store;