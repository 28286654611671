import './SurveyPage.scss';

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {useState} from "react";
import Spinner from "../../ui/spinner/Spinner";
import IconButton from "../../ui/icon-button/IconButton";
import CreateSurveyForm from "../../components/survey/create/CreateSurveyForm";
import {surveysActions} from "../../store/surveys";
import {SurveyService} from "../../data/survey/Survey.service";
import SurveyTile from "../../components/survey/tile/SurveyTile";
import {Survey} from "../../data/survey/Survey";
import ConfirmYesCancel from '../../ui/modal/ConfirmYesCancel';
import {MeServiceTokenPrices} from '../../data/payment/Payment';


const SurveyPage = () => {

    const dispatch = useDispatch();
    const surveys = useSelector((state: RootState) => state.surveys.surveys);
    const loading = useSelector((state: RootState) => state.surveys.loading);
    const companyId = useSelector((state: RootState) => state.company?.data?.id) as string;

    const [showMutation, setShowMutation] = useState(false);
    const [surveyContext, setSurveyContext] = useState<Survey>();
    const [showNotEnoughCreditsForm, setShowNotEnoughCreditsForm] = useState(false);
    const [showBuyTokensForm, setShowBuyTokensForm] = useState(false);

    function refreshSurveys() {
        dispatch(surveysActions.loading(true));
        SurveyService.search(companyId).then(surveys => {
            dispatch(surveysActions.loading(false));
            dispatch(surveysActions.replaceAll(surveys))
        });
    }


    function onEditSurveyCLicked(surveyId: string) {
        const survey = surveys.find(s => s.surveyId === surveyId) as unknown as Survey;
        setSurveyContext(survey);
        setShowMutation(() => true);
    }

    function onNewSurveyClicked() {
        setSurveyContext(undefined);
        setShowMutation(() => true);
    }

    function handleError(errorCode: number) {
        if (errorCode == 402) {
            setShowNotEnoughCreditsForm(true);
        }

        refreshSurveys();
    }

    function closeNotEnoughTokensDialog() {
        setShowNotEnoughCreditsForm(false);
    }

    function openBuyTokensDialog() {
        setShowNotEnoughCreditsForm(false);
        setShowBuyTokensForm(true);
    }

    function closeBuyTokensDialog() {
        setShowBuyTokensForm(false);
    }

    return (
        <>
            {loading && <Spinner/>}
            {!loading && <div className='me-page-container'>
                <div className='me-surveys-page-menu'>
                    <div className={'me-flex-grow'}>
                        <IconButton iconName={'add'} onClick={onNewSurveyClicked}/>
                    </div>
                </div>
                <div className='me-survey-page-list-container'>
                    {surveys.map(survey => (
                        <SurveyTile
                            key={survey.surveyId}
                            survey={survey}
                            onEdit={onEditSurveyCLicked}
                            onDeleted={refreshSurveys}
                            onActivated={refreshSurveys}
                            onError={handleError}
                        />
                    ))}
                </div>
            </div>}
            <CreateSurveyForm
                showModal={showMutation}
                intialSurvey={surveyContext}
                onSave={() => {
                    setShowMutation(false);
                    refreshSurveys();
                }}
                onCancel={() => setShowMutation(false)}/>
            <ConfirmYesCancel
                showModal={showNotEnoughCreditsForm}
                title='Fehler bei der Veröffentlichung'
                textYes='Tokens kaufen'
                message={'Sie verfügen nicht über genügend Tokens um die Umfrage zu veröffentlichen. Sie benötigen mindestens ' + MeServiceTokenPrices.SURVEY_PRICE.toString() + '.'}
                onCancel={closeNotEnoughTokensDialog}
                onConfirm={openBuyTokensDialog}
            />
        </>
    )
}

export default SurveyPage;