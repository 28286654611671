import Company from "../data/company/Company";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface CompanyState {
    data: Company | null;
    loading: boolean;
}

const initialState: CompanyState = {
    data: null,
    loading: false,
}

const companySlice = createSlice({
    name: 'company',
    initialState: initialState,
    reducers: {
        setCompany(state, action: PayloadAction<Company>) {
            state.data = action.payload;
        },
        removeCompany(state) {
            state.data = null;
        },
        loading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    }
})

export const companyActions = companySlice.actions;
export default companySlice.reducer;