import './SurveyPreview.scss';

import {Survey} from "../../../data/survey/Survey";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import Image from "react-bootstrap/Image";
import IconButton from "../../../ui/icon-button/IconButton";

export interface SurveyPreviewProps {
    survey: Survey;
}

const SurveyPreview = (props: SurveyPreviewProps) => {

    const company = useSelector((state: RootState) => state.company?.data);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

    const questions = props.survey.questions;
    const intro = props.survey.intro;
    const confirm = props.survey.confirm;
    const activeQuestion = questions[activeQuestionIndex];
    const showIntro = activeQuestionIndex === -1;
    const showSlices = activeQuestionIndex >= 0 && activeQuestionIndex < questions.length;
    const showConfirm = activeQuestionIndex === questions.length;
    const showBackButton = activeQuestionIndex > startIndex();
    const showNextButton = activeQuestionIndex < questions.length;

    useEffect(() => {
        setActiveQuestionIndex(startIndex());
    }, [props.survey])

    function onBackClicked() {
        setActiveQuestionIndex(activeQuestionIndex - 1);
    }

    function onNextClicked() {
        if (activeQuestionIndex < questions.length) {
            setActiveQuestionIndex(activeQuestionIndex + 1);
        }
    }

    function startIndex() {
        return intro ? -1 : 0;
    }

    return (
        <div className='me-survey-preview'>
            <div className='me-survey-preview-app-container'>
                <div className='me-survey-preview-content'>
                    {showIntro &&
                        <div className='me-survey-preview-intro'>
                            <div className='me-survey-preview-intro-header'>{intro?.title}</div>
                            <div className='me-survey-preview-intro-description'>{intro?.description}</div>
                        </div>
                    }
                    {showSlices &&
                        <div className='me-survey-preview-slice'>
                            <div className='me-survey-preview-question'>{activeQuestion.question}</div>
                            <div className='me-survey-preview-answers'>
                                {activeQuestion.answers.map(answer => {
                                    return <div key={answer.text} className={`me-survey-preview-answer`}>
                                        <div>{answer.text}</div>
                                    </div>
                                })}
                            </div>
                        </div>
                    }
                    {showConfirm &&
                        <div className='me-survey-preview-confirm-header'>{confirm.title}</div>
                    }
                </div>
                <div className='me-survey-preview-company-info'>
                    {company?.image &&
                        <Image src={company?.image} rounded={true}
                               alt='Company'/>}
                    <div>{company?.company}</div>
                </div>
            </div>
            <div className='me-survey-preview-footer'>
                <IconButton
                    disabled={!showBackButton}
                    onClick={onBackClicked}
                    iconName={'angle-left'}/>
                <IconButton
                    disabled={!showNextButton}
                    onClick={onNextClicked}
                    iconName={'angle-right'}/>
            </div>
        </div>
    )

}

export default SurveyPreview;