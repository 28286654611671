import {SwissCanton} from "./swiss.canton";

export class StateUtil {

    static swissCantons() {
        return [
            {code: SwissCanton.AG, label: 'Aargau'},
            {code: SwissCanton.AR, label: 'Appenzell Ausserrhoden'},
            {code: SwissCanton.AI, label: 'Appenzell Innerrhoden'},
            {code: SwissCanton.BL, label: 'Basel-Landschaft'},
            {code: SwissCanton.BS, label: 'Basel-Stadt'},
            {code: SwissCanton.BE, label: 'Bern'},
            {code: SwissCanton.FR, label: 'Freiburg'},
            {code: SwissCanton.GE, label: 'Genf'},
            {code: SwissCanton.GL, label: 'Glarus'},
            {code: SwissCanton.GR, label: 'Graubünden'},
            {code: SwissCanton.JU, label: 'Jura'},
            {code: SwissCanton.LU, label: 'Luzern'},
            {code: SwissCanton.NE, label: 'Neuenburg'},
            {code: SwissCanton.NW, label: 'Nidwalden'},
            {code: SwissCanton.OW, label: 'Obwalden'},
            {code: SwissCanton.SG, label: 'St. Gallen'},
            {code: SwissCanton.SH, label: 'Schaffhausen'},
            {code: SwissCanton.SZ, label: 'Schwyz'},
            {code: SwissCanton.SO, label: 'Solothurn'},
            {code: SwissCanton.TG, label: 'Thurgau'},
            {code: SwissCanton.TI, label: 'Tessin'},
            {code: SwissCanton.UR, label: 'Uri'},
            {code: SwissCanton.VD, label: 'Waadt'},
            {code: SwissCanton.VS, label: 'Wallis'},
            {code: SwissCanton.ZG, label: 'Zug'},
            {code: SwissCanton.ZH, label: 'Zürich'}
        ]
    }

}
