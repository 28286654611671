import {Survey} from "../data/survey/Survey";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface SurveysState {
    loading: boolean;
    surveys: Survey[];
}

const initialState: SurveysState = {
    loading: false,
    surveys: []
}

const surveysSlice = createSlice({
    name: 'surveys',
    initialState: initialState,
    reducers: {
        loading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        replaceAll(state, action: PayloadAction<Survey[]>) {
            state.surveys = action.payload;
        },
        replaceOne(state, action: PayloadAction<Survey>) {
            const surveys = state.surveys.filter(s => s.surveyId !== action.payload.surveyId);
            surveys.push(action.payload);
            state.surveys = surveys;
        },
    }
})

export const surveysActions = surveysSlice.actions;
export default surveysSlice.reducer;