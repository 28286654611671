import './EditCompanyTeam.scss';

import {Alert, Button, Form, Modal} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import IconButton from "../../../../ui/icon-button/IconButton";
import InfoListBox from "../../../../ui/info-box/info-list-box";
import {CompanyTeam, CompanyTeamImage, CompanyTeamMember} from "../../../../data/company/team/CompanyTeam";
import {CompanyTeamService} from "../../../../data/company/team/CompanyTeam.service";
import EditCompanyTeamMemeberRow from "./EditCompanyTeamMemeberRow";
import EditCompanyTeamMemberPopup from "./EditCompanyTeamMemberPopup";
import EditCompanyTeamImagePopup from "./EditCompanyTeamImagePopup";
import Image from "react-bootstrap/Image";
import {StringUtil} from "../../../../data/StringUtil";

export interface EditCompanyTeamProps {
    showModal: boolean;
    onSave: () => void;
    onCancel: () => void;
}

const EditCompanyTeam = (props: EditCompanyTeamProps) => {

    const companyId = useSelector((state: RootState) => state.company?.data?.id);
    const [teamMainDescription, setTeamMainDescription] = useState<string>('');
    const [teamImages, setTeamImages] = useState<CompanyTeamImage[]>([]);

    const [members, setMembers] = useState<CompanyTeamMember[]>([]);
    const [memberEdit, setMemberEdit] = useState<CompanyTeamMember | null>(null);
    const [showTeamForm, setShowTeamForm] = useState(false);
    const [showMemberForm, setShowMemberForm] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        if (props.showModal) {
            loadTeam();
        }
        // eslint-disable-next-line
    }, [props.showModal])

    function loadTeam() {
        setIsLoading(true);
        CompanyTeamService.search(companyId as string).then(team => {
            setIsLoading(false);
            if (team) {
                setTeamMainDescription(team.description);
                setTeamImages(team.teamImages);
                setMembers(team.members);
            }
        });
    }

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function resetForm() {
        setErrorText('');
    }

    async function save() {
        try {
            setErrorText('');
            setSaving(true);
            const team: CompanyTeam = {
                companyId: companyId as string,
                description: teamMainDescription,
                teamImages: teamImages,
                members: members,
            }
            await CompanyTeamService.update(team)
            setSaving(false);
            cancel();
        } catch (e: any) {
            setSaving(false)
            if (e.response?.data === 'request entity too large') {
                setErrorText('Die Dateien sind zu gross, bitte reduzieren Sie die Grösse!');
            } else {
                setErrorText('Fehler bei Speicherung...');
            }
        }
    }

    function removeItem(id: string) {
        const copiedMembers = [...members];
        const index = members.map(member => member.id).indexOf(id);
        copiedMembers.splice(index, 1);
        setMembers(copiedMembers);
    }

    function moveItemUp(id: string) {
        const member = members.find(member => member.id === id) as CompanyTeamMember;
        const fromIndex = members.map(q => q.id).indexOf(id);
        const copiedMembers = members.filter(q => q.id !== id);
        const newIndex = fromIndex - 1;
        copiedMembers.splice(newIndex, 0, member)
        setMembers(copiedMembers);
    }

    function onMemberClicked(member: CompanyTeamMember) {
        setShowMemberForm(true);
        setMemberEdit(member);
    }

    function onNewMemberClicked() {
        setShowMemberForm(true);
        setMemberEdit(null);
    }

    function addMember(member: CompanyTeamMember) {
        const index = members.map(item => item.id).indexOf(member.id);
        if (index > -1) {
            members[index] = member;
        } else {
            const copiedItems = [...members, member];
            setMembers(copiedItems);
        }
        setMemberEdit(null);
        setShowMemberForm(false);
    }

    function onTeamMainDescriptionChanged(event: any) {
        setErrorText('');
        setTeamMainDescription(event.target.value)
    }

    function addTeamImage(teamImage: CompanyTeamImage) {
        teamImages.length = 0;
        teamImages.push(teamImage);
        setTeamImages(teamImages);
        setShowTeamForm(false);
    }

    function deleteTeamImage(e: any) {
        e.stopPropagation();
        setTeamImages([]);
    }

    return <>
        <Modal show={props.showModal}
               backdrop={true}
               onHide={cancel}
               size={'lg'}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>Team bearbeiten</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Spinner/>}
                {!isLoading && <div className={'me-edit-company-team'}>
                    <InfoListBox iconPlacement={'top-right'} texte={[
                        'Du kannst einen Text über dein Team erfassen. Dieser wird auf deinem Profil deinen Followern angezeigt.',
                        'Erfasse ein Hauptbild von deinem Team und weitere Bilder von Teammitgliedern um euch vorzustellen.'
                    ]}/>
                    <Form.Group className="mt-3">
                        <Form.Control
                            as="textarea" rows={5}
                            maxLength={500}
                            placeholder={'Beschreibung allgemein ... '}
                            value={teamMainDescription}
                            onChange={onTeamMainDescriptionChanged}>
                        </Form.Control>
                        <div className={'me-max-character-indicator'}>{teamMainDescription?.length}/500</div>
                    </Form.Group>


                    {teamImages.length === 1 &&
                        <>
                            <div className={'me-edit-company-team-image-preview'} onClick={() => setShowTeamForm(true)}>
                                <Image src={teamImages[0].image} alt=''/>
                                <div className={'me-flex-column me-flex-grow'}>
                                    <div
                                        className={'me-edit-company-team-image-preview-title'}>{StringUtil.cut(teamImages[0].title, 25)}</div>
                                    <div className={'me-flex-row me-justify-flex-end me-margin-md'}>
                                        <IconButton
                                            className={'red-hover'} iconName={'trash-can'}
                                            onClick={deleteTeamImage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    <div className={'me-edit-company-team-add-member-buttons'}>
                        {teamImages.length === 0 && <IconButton
                            buttonLabel={'Team'} iconName={'people-group'} onClick={() => setShowTeamForm(true)}
                        />}
                        <IconButton
                            disabled={members.length > 4}
                            buttonLabel={'+ Person'} iconName={'user'} onClick={onNewMemberClicked}
                        />
                    </div>
                    {
                        members.map((item, index) => {
                            return <div key={item.id} className={'me-flex-row me-margin-lg-bottom'}>
                                <EditCompanyTeamMemeberRow
                                    number={++index}
                                    memeber={item}
                                    onClick={member => onMemberClicked(member)}
                                    onRemove={() => removeItem(item.id)}
                                    onMoveUp={() => moveItemUp(item.id)}
                                />
                            </div>
                        })
                    }
                    <div className={'me-max-character-indicator left'}>{members.length}/5 Personen</div>
                </div>}
                {errorText && <Alert variant='danger'>{errorText}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
            {showTeamForm &&
                <EditCompanyTeamImagePopup
                    editTeamImage={teamImages[0]}
                    addTeamImage={addTeamImage}
                    close={() => setShowTeamForm(false)}
                />
            }
            {showMemberForm &&
                <EditCompanyTeamMemberPopup
                    editMember={memberEdit}
                    addMember={member => addMember(member)}
                    close={() => setShowMemberForm(false)}
                />
            }
        </Modal>
    </>
}

export default EditCompanyTeam;