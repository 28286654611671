import {Client, ClientContact} from "./Client";

export class ClientUtil {

    static concatHome(contact: ClientContact) {
        let home = '';
        if (contact.zip) {
            home += contact.zip + ' ';
        }
        if (contact.city) {
            home += contact.city;
        }
        return home;
    }

    static sort(a: Client, b: Client): number {
        return a.name.localeCompare(b.name) || a.firstname.localeCompare(b.firstname);
    }
}