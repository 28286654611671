type ObjectType = { [key: string]: any };

function areObjectsEqual(obj1: ObjectType, obj2: ObjectType): boolean {
    if (obj1 === obj2) return true;
    if (obj1 == null || obj2 == null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];
        if (typeof val1 === 'object' && typeof val2 === 'object') {
            if (!areObjectsEqual(val1, val2)) {
                return false;
            }
        } else {
            if (val1 !== val2) {
                return false;
            }
        }
    }
    return true;
}

export function areArraysEqual(arr1: ObjectType[], arr2: ObjectType[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const sortedArr1 = arr1.slice().sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
    const sortedArr2 = arr2.slice().sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
    for (let i = 0; i < sortedArr1.length; i++) {
        if (!areObjectsEqual(sortedArr1[i], sortedArr2[i])) {
            return false;
        }
    }
    return true;
}

