import './LeftFlypanel.scss';
import React from "react";
import {CloseButton} from "react-bootstrap";

export interface LeftFlypanelProps {
    children: any;
    title: string;
    show: boolean;
    width: string;
    onClose: () => void;
}

const LeftFlypanel = (props: LeftFlypanelProps) => {

    function onCloseHandler(event: any) {
        event.stopPropagation();
        props.onClose();
    }

    const customStyle: any = {
        left: '-' + props.width,
        width: props.width
    }

    return (
        <>
            {props.show && <div className={'me-left-flypanel-backdrop'} onClick={props.onClose}/>}
            <div style={customStyle}
                 className={`me-left-flypanel ${props.show ? 'show' : ''}`}>
                <div className={`me-left-flypanel-header`}>
                    <div className={`title`}>{props.title}</div>
                    <CloseButton onClick={onCloseHandler}/>
                </div>
                <div className='me-left-flypanel-content'> {props.children}</div>
            </div>
        </>
    )

};

export default LeftFlypanel;