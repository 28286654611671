import axiosInstance from "../../../AxiosInterceptor";
import {CompanyVrTour, CompanyWeblinks, CompanyWeblinksItem} from "./CompanyWeblinks";

export class CompanyWeblinksService {

    public static async search(companyId: string): Promise<CompanyWeblinks | null> {
        const response = await axiosInstance.get(`companies/${companyId}/weblinks/`);
        return this.mapWebLinks(response.data);
    }

    public static async update(weblinks: CompanyWeblinks): Promise<void> {
        return await axiosInstance.post('/companies/profile/weblinks', weblinks);
    }

    private static mapWebLinks(data: any): CompanyWeblinks | null {
        if (!data) {
            return null;
        }
        return {
            bookingLink: data.bookingLink,
            website: data.website,
            vrTour: this.mapVrTour(data.vrTour),
            weblinksItems: data.items.map(this.mapWeblinksItems)
        }
    }

    private static mapVrTour(data: any): CompanyVrTour {
        return {
            url: data.url,
            image: data.image
        }
    }


    private static mapWeblinksItems(data: any): CompanyWeblinksItem {
        return {
            code: data.code,
            url: data.url
        }
    }


}