import './Dashboard.scss'
import DashboardCard from "./dashboardcard/dashboardCard";
import {RouteNames} from "../../route-names";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Event} from "../../data/event/Event";
import React, {useEffect, useState} from "react";
import {DetailInfo} from "../../data/dashboard/DetailInfo";
import {Post} from "../../data/post/Post";
import {isEventActive, isRunningEvent} from "../../data/event/EventUtil";
import {isPostActive} from "../../data/post/PostUtil";
import {Survey} from "../../data/survey/Survey";
import {isSurveyActive} from "../../data/survey/SurveyUtil";
import {Chat} from "../../data/chat/Chat";

const Dashboard = () => {

    const [clientInfos, setClientInfos] = useState([] as DetailInfo[]);
    const [eventInfos, setEventInfos] = useState([] as DetailInfo[]);

    const dashboardRerfreshDate = useSelector((state: RootState) => state.application.dashboardRerfreshDate);

    const chats = useSelector((state: RootState) => state.chats.chats) as unknown as Chat[];
    const chatsLoading = useSelector((state: RootState) => state.chats.loading);

    const events = useSelector((state: RootState) => state.events.events) as unknown as Event[];
    const eventCount = events.filter(isEventActive).length;
    const eventsLoading = useSelector((state: RootState) => state.events.loading);

    const posts = useSelector((state: RootState) => state.posts.posts) as unknown as Post[];
    const postCount = posts.filter(isPostActive).length;
    const postLoading = useSelector((state: RootState) => state.posts.loading);

    const subscriptionsLoading = useSelector((state: RootState) => state.subscriptions.loading);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const surveys = useSelector((state: RootState) => state.surveys.surveys) as unknown as Survey[];
    const surveyCount = surveys.filter(isSurveyActive).length;
    const surveyLoading = false;

    const navigate = useNavigate();
    const location = useLocation();


    function onCardClick(routename: RouteNames) {
        navigate(routename);
    }

    function isCardSelected(routename: RouteNames) {
        return location.pathname.endsWith(routename.toLowerCase());
    }

    useEffect(() => {
        setEventInfos(() => calculateEvent(events));
    }, [events.length, dashboardRerfreshDate])

    useEffect(() => {
        const clientInfos: DetailInfo[] = [];
        const contactNumber = subscriptions.filter(s => s.isContact).length;
        clientInfos.push({title: 'Kontakte', count: contactNumber, iconName: 'user'});
        setClientInfos(clientInfos);
    }, [subscriptions.length, dashboardRerfreshDate])

    function calculateEvent(events: Event[]): DetailInfo[] {
        const eventInfos: DetailInfo[] = [];
        const relevantEvents = events.filter(e => isEventActive(e));

        const runningEventsCount = relevantEvents.filter(e => isRunningEvent(e)).length;

        if (runningEventsCount > 0) {
            eventInfos.push({title: 'Laufend', count: runningEventsCount, iconName: 'person-running'});
        }

        return eventInfos;
    }

    return (
        <div className='me-dashboard-cards'>
            <DashboardCard
                tourSelector={'me-follower-card'}
                selected={isCardSelected(RouteNames.CLIENTS)}
                iconName="globe" title='Follower' value={subscriptions.length as unknown as string}
                detailInfos={clientInfos}
                loading={subscriptionsLoading}
                onClick={() => onCardClick(RouteNames.CLIENTS)}/>
            <DashboardCard
                tourSelector={'me-mitteilungen-card'}
                selected={isCardSelected(RouteNames.POSTS)}
                iconName="paper-plane"
                title='Posts' value={postCount as unknown as string}
                detailInfos={[]}
                loading={postLoading}
                onClick={() => onCardClick(RouteNames.POSTS)}/>
            <DashboardCard
                tourSelector={'me-events-card'}
                selected={isCardSelected(RouteNames.EVENTS)}
                iconName="calendar-days"
                title='Events' value={eventCount as unknown as string}
                detailInfos={eventInfos}
                loading={eventsLoading}
                onClick={() => onCardClick(RouteNames.EVENTS)}/>
            <DashboardCard
                tourSelector={'me-chats-card'}
                selected={isCardSelected(RouteNames.CHATS)}
                iconName="comments" title='Chats' value={chats.length as unknown as string}
                detailInfos={[]}
                loading={chatsLoading}
                onClick={() => onCardClick(RouteNames.CHATS)}/>
            <DashboardCard
                tourSelector={'me-survey-card'}
                selected={isCardSelected(RouteNames.SURVEY)}
                iconName="file-circle-question"
                title='Umfragen' value={surveyCount as unknown as string}
                detailInfos={[]}
                loading={surveyLoading}
                onClick={() => onCardClick(RouteNames.SURVEY)}/>
        </div>
    )

}

export default Dashboard;