export enum RouteNames {

    EVENTS = 'events',

    CHATS = 'chats',

    CLIENTS = 'clients',

    POSTS = 'posts',

    SURVEY = 'survey',

    LOGIN = 'login',

    SIGNUP = 'signup',

    PASSWORD_RESET = 'passwordReset',

}