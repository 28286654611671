import './AccountState.scss'
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {CloseButton} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";

const AcountState = () => {


    const company = useSelector((state: RootState) => state.company?.data);

    const hasImage = company?.image && company?.image.length > 0;
    const hasTelefon = company?.telefon && company?.telefon.length > 0;
    const hasAdresse = company?.address && company?.address.street?.length > 0 && company?.address.zip > 0;
    const [hideState, setHideState] = useState(false);

    let show = company && !hideState && (!hasImage || !hasAdresse || !hasTelefon);
    if (show) {
        show = !sessionStorage.getItem('isWelcomeTour') && !sessionStorage.getItem('newCompanySignUp');
    }

    return (

        <>
            {show && <div className={'me-account-state'}>
                <FontAwesomeIcon className={'me-account-state-arrow'} icon={'arrow-left'}/>
                <div className={'px-3'}>
                    <div className={'me-account-state-header'}><b>Dein Konto ist nicht sichtbar!</b><br/><br/> Bitte
                        vervollständige das Profil:
                    </div>
                    <ul>
                        {!hasImage && <li className={'me-account-state-detail'}>Logo</li>}
                        {!hasAdresse && <li className={'me-account-state-detail'}>Adresse</li>}
                        {!hasTelefon && <li className={'me-account-state-detail'}>Telefonnummer</li>}
                    </ul>
                </div>
                <CloseButton variant={'white'} onClick={() => setHideState(true)}/>
            </div>}
        </>

    )


}


export default AcountState;