import './ClientsOverview.scss';

import {useEffect, useState} from "react";
import {Client} from "../../../data/client/Client";
import {ClientService} from "../../../data/client/Client.service";
import {Alert, Table} from "react-bootstrap";
import Spinner from "../../../ui/spinner/Spinner";

export interface ClientsOverviewProps {
    clientIds: string[];
}

const ClientsOverview = (props: ClientsOverviewProps) => {

    const [hasInvalidClients, setHasInvalidClients] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [clients, setClients] = useState<Client[]>([]);

    useEffect(() => {
        setLoading(true);
        setError('');
        ClientService.readBulk(props.clientIds).then(bulk => {
            setClients(bulk.clients);
            setHasInvalidClients(bulk.clientsNotFound.length > 0);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            setError(err.message);
        });
    }, [])

    return (
        <>
            <div className='me-flex-grow'>
                {error && <Alert variant={"danger"}>{error}</Alert>}
                {loading && <Spinner></Spinner>}
                {!loading && !error &&
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                        </tr>
                        </thead>
                        <tbody className={'me-client-table-body'}>
                        {clients.map(client => (
                            <tr key={client.id}>
                                <td>{client.firstname} {client.name}</td>
                                <td>{client.email}</td>
                            </tr>
                        ))}
                        {clients.length === 0 &&
                            <tr>
                                <td colSpan={3}> Noch kein Kunde vorhanden</td>
                            </tr>}
                        </tbody>
                    </Table>}
            </div>
            {hasInvalidClients && <Alert variant='warning'>
                Nicht alle Kunden konnten geladen werden. Bitte benachrichtigen sie den Support
            </Alert>}
        </>
    )

}

export default ClientsOverview;