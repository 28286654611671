export function isLocalhost() {
    return false;
}

export function host() {
    if (isLocalhost()) {
        return "http://localhost:5000/"; // ME_DEV
    } else {
        return "https://me.onra.ch/api/"; // ME PRODUCTION
    }
}