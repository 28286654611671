import {useEffect} from "react";
import {eventsActions} from "./store/events";
import {EventService} from "./data/event/Event.service";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./store/store";
import {subscriptionActions} from "./store/subscriptions";
import {SubscriptionService} from "./data/subscription/Subscription.service";
import {PostService} from "./data/post/Post.service";
import {surveysActions} from "./store/surveys";
import {SurveyService} from "./data/survey/Survey.service";
import {chatsActions} from "./store/chats";
import {ChatService} from "./data/chat/ChatService";
import {postsActions} from "./store/posts";
import {companyActions} from "./store/company";
import {CompanyService} from "./data/company/Company.service";
import {gemeindeActions} from "./store/gemeinde";
import {GemeindeService} from "./data/gemeinde/Gemeinde.service";

/**
 * Loads Date relevant for starting the app example Dashboard data.
 */
const AppDataLoader = () => {

    const dispatch = useDispatch();
    const companyId = useSelector((state: RootState) => state.account.data?.companyId);
    const gemeindeId = useSelector((state: RootState) => state.account.data?.gemeindeId);

    useEffect(() => {
        if (companyId) {

            dispatch(companyActions.loading(true));
            CompanyService.read(companyId).then(company => {
                dispatch(companyActions.loading(false));
                dispatch(companyActions.setCompany(company))
            });

            dispatch(chatsActions.loading(true));
            ChatService.search().then(chats => {
                dispatch(chatsActions.loading(false));
                dispatch(chatsActions.replaceAll(chats))
            });

            dispatch(eventsActions.loading(true));
            EventService.searchEvents(companyId).then(events => {
                dispatch(eventsActions.loading(false));
                dispatch(eventsActions.replaceAll(events))
            });

            dispatch(postsActions.loading(true));
            PostService.search(companyId).then(notifications => {
                dispatch(postsActions.loading(false));
                dispatch(postsActions.replaceAll(notifications))
            });

            dispatch(subscriptionActions.loading(true));
            SubscriptionService.search(companyId).then(subscriptions => {
                dispatch(subscriptionActions.loading(false));
                dispatch(subscriptionActions.replaceAll(subscriptions))
            });

            dispatch(surveysActions.loading(true));
            SurveyService.search(companyId).then(surveys => {
                dispatch(surveysActions.loading(false));
                dispatch(surveysActions.replaceAll(surveys))
            });


        }
        if (gemeindeId) {
            dispatch(gemeindeActions.loading(true));
            GemeindeService.read(gemeindeId).then(gemeinde => {
                dispatch(gemeindeActions.loading(false));
                dispatch(gemeindeActions.setGemeinde(gemeinde))
            });
            PostService.search(gemeindeId).then(notifications => {
                dispatch(postsActions.loading(false));
                dispatch(postsActions.replaceAll(notifications))
            });
        }

    }, [companyId, gemeindeId]);

    return (
        <div style={{background: 'red'}}></div>
    )

}

export default AppDataLoader;