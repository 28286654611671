import axiosInstance from "../../../AxiosInterceptor";
import {CompanyTeam, CompanyTeamImage, CompanyTeamMember} from "./CompanyTeam";

export class CompanyTeamService {

    public static async search(companyId: string): Promise<CompanyTeam | null> {
        const response = await axiosInstance.get(`companies/${companyId}/team/`);
        return this.mapTeam(response.data);
    }

    public static async update(team: CompanyTeam): Promise<void> {
        return await axiosInstance.post('/companies/team', team);
    }

    private static mapTeam(data: any): CompanyTeam | null {
        if (!data) {
            return null;
        }
        return {
            companyId: data.companyId,
            description: data.description,
            teamImages: data.teamImages.map(this.mapTeamImages),
            members: data.members.map(this.mapMember)
        }
    }

    private static mapTeamImages(data: any): CompanyTeamImage {
        return {
            id: data.id,
            image: data.image,
            title: data.title,
            description: data.description
        }
    }

    private static mapMember(data: any): CompanyTeamMember {
        return {
            id: data.id,
            firstname: data.firstname,
            name: data.name,
            role: data.role,
            image: data.image,
            description: data.description
        }
    }


}