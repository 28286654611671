import './Signup.scss';
import React, {Fragment, useState} from "react";
import {AuthService} from "../../data/auth/Auth.service";
import {Alert, Button, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {RouteNames} from "../../route-names";
import {SignupData} from "../../data/auth/SignupData";
import validator from "validator";
import Spinner from "../../ui/spinner/Spinner";
import {useDispatch} from "react-redux";

// @ts-ignore
import LoginScreenSVG from "../../images/login_screen.svg";
// @ts-ignore
import LogoAnimatedSVG from "../../images/logo_animated_converted.svg";
import {PasswordService} from "../../data/auth/Password.service";
import {accountActions} from "../../store/account";

const Signup = () => {

    const dispatch = useDispatch();

    // Fields
    const [email, setEmail] = useState('');
    const [emailBlur, setEmailBlur] = useState(false);
    const [emailValid, setEmailValid] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);

    const [company, setCompany] = useState('');
    const [companyValid, setCompanyValid] = useState(false);

    const [termsAndCondition, setTermsAndCondition] = useState(false);

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [isSignup, setIsSignup] = useState(false);
    const [isEnterCodeStep, setEnterCodeStep] = useState(false);
    const [confirmCode, setConfirmCode] = useState('');

    const navigate = useNavigate();

    function onPasswordChangeHandler(event: any) {
        const newValue = event.target.value;
        setPassword(newValue);
        setPasswordValid(newValue && newValue.length >= 8)
    }

    function onCodeChangeHandler(event: any) {
        const newValue = event.target.value;
        setConfirmCode(newValue);
    }

    function onEmailChangeHandler(event: any) {
        const newValue = event.target.value;
        setEmail(() => newValue);
        setEmailValid(validator.isEmail(newValue));
    }

    function onEmailBlur() {
        setEmailBlur(true);
        setEmailValid(validator.isEmail(email));
    }

    function onCompanyChangeHandler(event: any) {
        const newValue = event.target.value;
        setCompany(newValue);
        setCompanyValid(newValue && newValue.length > 0);
    }

    function onTermsAndConditionCheckHander(event: any) {
        const newValue = event.target.checked;
        setTermsAndCondition(newValue);
    }

    function onFormSubmitHandler(event: any) {
        event.preventDefault();
        event.stopPropagation();
        setError('');
        setFormSubmitted(true);
        if (isValidForm()) {
            setIsSignup(true);
            if (!isEnterCodeStep) {
                AuthService.signupRequest(email).then((signupRequestResponse) => {
                    if (signupRequestResponse === PasswordService.EMAIL_SENT) {
                        setEnterCodeStep(true);
                        setIsSignup(false);
                        return;
                    }
                    if (signupRequestResponse === PasswordService.REQUEST_FAILED_EMAIL_ALREADY_IN_USE) {
                        setError('Ein Konto mit dieser E-Mail existiert bereits.');
                        setIsSignup(false);
                        return;
                    }
                });
                setIsSignup(false);
                return;
            }

            if (!confirmCode) {
                setError('Bitte geben Sie den Code ein, den Sie per E-Mail erhalten haben.')
                setIsSignup(false);
                return;
            }

            const signupData = new SignupData(company, email, password, confirmCode);
            AuthService.signup(signupData)
                .then(account => {
                    sessionStorage.setItem("newCompanySignUp", 'true');
                    dispatch(accountActions.setAccount(account));
                    setIsSignup(false);
                    navigateClients();
                })
                .catch((e) => {
                    setIsSignup(false);
                    if (e.message === PasswordService.REQUEST_FAILED_CODE_NOT_EQUAL) {
                        setError('Der angegebene Code stimmt nicht mit unserer Datenbank überein.');
                        return;
                    }
                    setError('Anmeldung zurzeit nicht möglich!');
                });
        }
    }

    function isValidForm() {
        return companyValid && emailValid && passwordValid && termsAndCondition;
    }

    function navigateLogin() {
        navigate('/' + RouteNames.LOGIN);
    }

    function navigateClients() {
        navigate("/" + RouteNames.CLIENTS)
    }


    return (
        <Fragment>
            <div className='me-signup-container'>
                <img className='me-signup-background' src={LoginScreenSVG} alt={''}></img>
                <div className='me-signup-form-container'>
                    <img className='me-signup-logo' src={LogoAnimatedSVG} alt={''}></img>
                    <Form className='me-signup-form'
                          noValidate
                          onSubmit={onFormSubmitHandler}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name*</Form.Label>
                            <Form.Control
                                isValid={companyValid}
                                isInvalid={!companyValid && formSubmitted}
                                onChange={onCompanyChangeHandler}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>E-Mail*</Form.Label>
                            <Form.Control
                                isValid={emailValid}
                                isInvalid={!emailValid && (emailBlur || formSubmitted)}
                                onBlur={onEmailBlur}
                                onChange={onEmailChangeHandler}/>
                            <Form.Control.Feedback type="invalid">
                                Email ist ungültig.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password*</Form.Label>
                            <Form.Control
                                isValid={passwordValid}
                                isInvalid={!passwordValid && formSubmitted}
                                type="password"
                                onChange={onPasswordChangeHandler}/>
                            <Form.Control.Feedback type="invalid">
                                Passwort muss min. 8 Zeichen lang sein.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {isEnterCodeStep &&
                            <Form.Group className="mb-3">
                                <Form.Label>Per E-Mail erhaltener Code*</Form.Label>
                                <Form.Control
                                    onChange={onCodeChangeHandler}/>
                            </Form.Group>
                        }
                        <Form.Group className="mb-3">
                            <Form.Check
                                label={
                                    <span> Ich akzeptiere die&nbsp;
                                        <a className={"me-signup-agb"} href={"https://onra.ch/me-agb/"} target="_blank"
                                           rel="noopener noreferrer">
                                         AGB.
                                    </a>
                                    </span>
                                }
                                feedback="You must agree before submitting."
                                feedbackType="invalid"
                                isValid={termsAndCondition}
                                onChange={onTermsAndConditionCheckHander}
                                isInvalid={!termsAndCondition && formSubmitted}
                            />
                        </Form.Group>
                        {!isSignup && <Button className='me-signup-button' variant={'outline-secondary'}
                                              type={"submit"}>Konto erstellen</Button>}
                        {isSignup && <Spinner></Spinner>}
                        {error.length > 0 && <Alert className='me-login-alert' variant='danger'>
                            {error}
                        </Alert>}
                    </Form>
                </div>
                <span className='me-signup-info'>
                    <div className='me-signup-info-text'>
                        <a className='me-signup-to-login-link' onClick={navigateLogin}>{'< Zurück zum Login'}</a>
                    </div>
                </span>
                <div className={'me-agb-impressum-container'}>
                    <a href={"https://onra.ch/me-agb/"} target="_blank"
                       rel="noopener noreferrer">
                        AGB
                    </a>
                    <div className={'me-divider'}>|</div>
                    <a href={"https://onra.ch/impressum/"} target="_blank"
                       rel="noopener noreferrer">
                        Impressum
                    </a>
                </div>
            </div>
        </Fragment>
    )

}


export default Signup;