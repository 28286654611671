import {DateUtil} from "../DateUtil";
import {Event, EventActionType} from "./Event";
import {ListValue} from "../../ui/list/List";

export function isRunningEvent(event: Event) {
    return event.dates.some(dateTime => {
        return DateUtil.isToday(dateTime.date);
    });
}

export function isEventActive(event: Event) {
    return event.dates.some(dateTime => {
        return DateUtil.isToday(dateTime.date) || DateUtil.isAfterNow(dateTime.date);
    });
}

export function sortEvent() {
    return (e1: Event, e2: Event) => {
        return new Date(e1.dates[0].date).getTime() - new Date(e2.dates[0].date).getTime();
    };
}

export class EventUtil {

    static externalLinkLabels(): ListValue[] {
        return [
            {code: 'BOOKING', label: 'Buchen'},
            {code: 'BUY_TICKETS', label: 'Ticket kaufen'},
            {code: 'BOOK_TABLE', label: 'Tisch reservieren'}
        ]
    }

    static actionTypes() {
        return [
            {code: EventActionType.NO_ACTION, label: 'Keine Aktion möglich'},
            {code: EventActionType.REGISTRATION_ME, label: 'Anmeldung über Me'},
            {code: EventActionType.EXTERNAL_LINK, label: 'Eigenen Link hinterlegen'}
        ]
    }

}

