export class SignupData {
    email: string;
    password: string;
    company: string;
    confirmCode: string;

    constructor(company: string, email: string, password: string, confirmCode: string) {
        this.company = company;
        this.email = email;
        this.password = password;
        this.confirmCode = confirmCode;
    }
}