import './visibility-toggler.scss'

import {ButtonGroup, ToggleButton} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {faGlobe, faListCheck, faUser} from "@fortawesome/free-solid-svg-icons";
import {Visibility} from "../../../data/base/Visibility";

export interface VisibilityTogglerProps {
    initialValue?: Visibility;
    onChange: (value: Visibility) => void;
}

const VisibilityToggler = (props: VisibilityTogglerProps) => {

    const [visibility, setVisibility] = useState<Visibility>(Visibility.PUBLIC);

    const radios = [
        {name: 'Follower', value: Visibility.PUBLIC, icon: faGlobe},
        {name: 'Kontakte', value: Visibility.CONFIRMED, icon: faUser},
        {name: 'Einladungsliste', value: Visibility.LIST, icon: faListCheck},
    ];

    useEffect(() => {
        if (props.initialValue) {
            setVisibility(props.initialValue);
        }
    }, [props.initialValue])

    function onVisibilityChanged(e: any) {
        setVisibility(e.target.value);
        props.onChange(e.target.value);
    }

    return (
        <ButtonGroup className='me-visibility-group'>
            {radios.map((radio, idx) => (
                <ToggleButton
                    className={visibility === radio.value ? 'me-checked' : ''}
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={'outline-secondary'}
                    name="radio"
                    value={radio.value}
                    checked={visibility === radio.value}
                    onChange={onVisibilityChanged}>
                    {radio.name} <FontAwesomeIcon icon={radio.icon}></FontAwesomeIcon>
                </ToggleButton>
            ))}
        </ButtonGroup>
    )
}

export default VisibilityToggler;