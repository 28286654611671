import './InvitationList.scss'

import Spinner from "../../../ui/spinner/Spinner";
import {Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Client} from "../../../data/client/Client";
import {ClientService} from "../../../data/client/Client.service";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import IconButton from "../../../ui/icon-button/IconButton";

interface ParticipantListProps {
    initialInvitationClientIds: string[];
    invitationClientIdsChanged: (clientIds: string[]) => void;
}

const InvitationList = (props: ParticipantListProps) => {

    const subscrptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [clients, setClients] = useState<Client[]>([]);
    const [invitationClientIds, setInvitationClientIds] = useState(props.initialInvitationClientIds);

    useEffect(() => {
        setLoading(true);
        setError('');
        const clientIds = subscrptions.map(s => s.clientId);
        ClientService.readBulk(clientIds).then(bulk => {
            setClients(bulk.clients);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            setError(err.message);
        });
    }, [])

    function addParticipant(e: any, clientId: string) {
        e.stopPropagation();
        invitationClientIds.push(clientId);
        setInvitationClientIds(() => [...invitationClientIds]);
        props.invitationClientIdsChanged(invitationClientIds);
    }

    function removeParticipant(e: any, clientId: string) {
        e.stopPropagation();
        const clientIds = invitationClientIds.filter(cId => cId !== clientId);
        setInvitationClientIds(() => clientIds);
        props.invitationClientIdsChanged(clientIds);
    }

    function isParticipant(clientId: string): boolean {
        return invitationClientIds.indexOf(clientId) >= 0;
    }

    return (
        <div className='me-invitation-list-container'>
            {error && <div>{error}</div>}
            {loading && <Spinner></Spinner>}
            {!loading && !error &&
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Kunde</th>
                        <th>Email</th>
                        <th className='width-content-fit'></th>
                    </tr>
                    </thead>
                    <tbody className='me-invitation-list-table-body'>
                    {clients && clients.length > 0 && clients
                        .sort((c1, c2) => {
                            return c1.name.localeCompare(c2.name);
                        })
                        .map(client => (
                            <tr key={client.id}>
                                <td>{client.name}</td>
                                <td>{client.email}</td>
                                <td>
                                    {!isParticipant(client.id) && <IconButton
                                        iconName={'check'}
                                        onClick={(e) => addParticipant(e, client.id)}/>}
                                    {isParticipant(client.id) && <IconButton
                                        className={'green'}
                                        iconName={'check'}
                                        onClick={(e) => removeParticipant(e, client.id)}/>}
                                </td>
                            </tr>
                        ))}
                    {(!clients || clients.length === 0) &&
                        <tr>
                            <td colSpan={3}> Noch kein Kunde vorhanden</td>
                        </tr>}
                    </tbody>
                </Table>}
        </div>
    )

}

export default InvitationList;