import './Profil.scss';
import {Alert, Button, Col, Modal, Row} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {CompanyProfilUpdate} from "../../../../data/company/Company";
import {CompanyService} from "../../../../data/company/Company.service";
import Form from "react-bootstrap/Form";
import {companyActions} from "../../../../store/company";

export interface EditGeneralSettingsProps {
    showModal: boolean;
    onSave: () => void;
    onCancel: () => void;
}

const EditGeneralSettingsForm = (props: EditGeneralSettingsProps) => {


    const dispatch = useDispatch();
    const [errorText, setErrorText] = useState('');
    const company = useSelector((state: RootState) => state.company.data);

    const [isSaving, setSaving] = useState(false);
    const [error, setError] = useState(false);


    // Form state
    const [name, setName] = useState(company?.company);
    const [email, setEmail] = useState(company?.email);
    const [vorwahl, setVorwahl] = useState(getVorwahlFromTelefonnummer(company?.telefon as string));
    const [telefonnummer, setTelefonnummer] = useState(getTelefonnummerWithoutVorwahl(company?.telefon as string));

    const formRef = useRef(null);


    function onNameChanged(event: any) {
        setError(false);
        setName(event.target.value);
    }

    function onEmailChanged(event: any) {
        setError(false);
        setEmail(event.target.value);
    }

    function onTelefonnummerChanged(event: any) {
        setError(false);
        setTelefonnummer(event.target.value);
    }

    function onVorwahlChanged(event: any) {
        setError(false);
        setVorwahl(event.target.value);
    }

    function resetForm() {
        setName(company?.company);
        setEmail(company?.email);
        setTelefonnummer(company?.telefon as string);
        setError(false);
        setErrorText('');
    }

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function save() {
        const form = formRef.current as any;
        setError(false);
        setErrorText('');
        setSaving(true);

        if (form.checkValidity() === false) {
            setError(true);
            setErrorText('Bitte alle Mussfelder ausfüllen');
            setSaving(false);
            return;
        }
        const telefon = vorwahl + telefonnummer;
        const companyUpdate: CompanyProfilUpdate = {
            id: company?.id as string,
            company: name as string,
            email: email as string,
            specialOpeningHours: company?.specialOpeningHours as any,
            telefon: telefon.replace(/\s/g, ""),
            image: company?.image as string
        }

        CompanyService.update(companyUpdate)
            .then(() => {
                CompanyService.read(companyUpdate.id).then(company => {
                    dispatch(companyActions.setCompany(company));
                    setSaving(false);
                    props.onSave();
                })
            })
            .catch(error => {
                setErrorText(error);
                setError(true);
            });
    }

    function getVorwahlFromTelefonnummer(telefonnummer: string): string {
        if (!telefonnummer) {
            return '+41';
        }
        return telefonnummer.slice(0, 3);
    }

    function getTelefonnummerWithoutVorwahl(telefonnummer: string): string {
        if (!telefonnummer) {
            return '';
        }
        return telefonnummer.slice(3, telefonnummer.length);
    }

    function createFormForGeneralSettings() {
        return <div className={'me-flex-column'}>
            <Form ref={formRef} className={'me-setting-general-form'}>
                <Form.Group className="mb-3">
                    <Form.Control
                        id='name'
                        placeholder={'Firmenname*'}
                        onChange={onNameChanged}
                        defaultValue={company?.company as any}
                        required>
                    </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control
                        id='email'
                        placeholder={'Email*'}
                        onChange={onEmailChanged}
                        defaultValue={company?.email as string}
                        required>
                    </Form.Control>
                </Form.Group>
                <Row className="mb-3">
                    <Form.Group as={Col} xs={2}>
                        <Form.Control
                            id='vorwahl'
                            placeholder={'+41*'}
                            onChange={onVorwahlChanged}
                            defaultValue={getVorwahlFromTelefonnummer(company?.telefon as any)}
                            required>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Control
                            id='telefonnummer'
                            placeholder={'00 000 00 00'}
                            onChange={onTelefonnummerChanged}
                            defaultValue={getTelefonnummerWithoutVorwahl(company?.telefon as any)}
                            required>
                        </Form.Control>
                    </Form.Group>
                </Row>
            </Form>
            {error &&
                <Alert className='settings-error' variant='danger'>{errorText}</Alert>
            }
        </div>
    }

    return <>
        <Modal show={props.showModal}
               backdrop="static"
               onHide={cancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Profil bearbeiten</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createFormForGeneralSettings()}</Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    </>

}

export default EditGeneralSettingsForm;