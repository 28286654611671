import Accordion from "react-bootstrap/Accordion";
import React, {useEffect, useState} from "react";
import AddressLocationsFormRow from "./AddressLocationsFormRow";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";
import IconButton from "../../ui/icon-button/IconButton";
import {AddressLocation} from "../../data/address/AddressLocation";

export interface AddressLocationsFormEditProps {
    locations: AddressLocation[];
    onChanged: (locations: AddressLocation[]) => void;
}

const AddressLocationsForm = (props: AddressLocationsFormEditProps) => {

    const [locations, setLocations] = useState<AddressLocation[]>(props.locations);
    const [activeKey, setActiveKey] = useState<string | null>(null);

    useEffect(() => {
        props.onChanged(locations);
    }, [locations]);

    function addLocation() {
        const newLocations = [...locations];
        const id = `${Date.now()}-${Math.random().toString(36).substring(2, 11)}`;
        newLocations.push({
            id: id,
            name: '',
            zip: '',
            coordinate: {
                longitude: '',
                latitude: ''
            },

        })
        setLocations(newLocations);
        setActiveKey(String(newLocations.length - 1));
    }


    function deleteLocation(id: string) {
        const newLocations = locations.filter(location => location.id !== id);
        setLocations(newLocations);
    }

    function onLocationChanged(location: AddressLocation) {
        const updatedLocations = [...locations];
        const index = updatedLocations.findIndex(loc => loc.id === location.id);
        if (index !== -1) {
            updatedLocations.splice(index, 1, {...updatedLocations[index], ...location});
        }
        setLocations(updatedLocations);
    }

    const handleAccordionSelect = (eventKey: AccordionEventKey | null) => {
        setActiveKey(eventKey as string | null);
    };


    return (
        <div>
            <Accordion activeKey={activeKey} onSelect={handleAccordionSelect}>
                {
                    locations.map((location, index) => {

                        return <Accordion.Item key={location.id} eventKey={String(index)}>
                            <Accordion.Header>
                                <div>{location.name}</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <AddressLocationsFormRow
                                    location={location}
                                    onDelete={() => deleteLocation(location.id)}
                                    onChanged={onLocationChanged}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    })
                }
            </Accordion>
            <div className={'me-margin-lg-top me-margin-xl-bottom'}>
                <IconButton iconName={'add'} onClick={addLocation} buttonLabel={'Kartenpunkt'}/>
            </div>
        </div>
    )


}

export default AddressLocationsForm;