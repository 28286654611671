export enum WeekDays {
    MONDAY = 1,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
}

export interface OpeningHour {
    weekday: WeekDays;
    from: string | undefined;
    to: string | undefined;
}

export interface OpeningHours {
    monday: OpeningHour;
    tuesday: OpeningHour;
    wednesday: OpeningHour;
    thursday: OpeningHour;
    friday: OpeningHour;
    saturday: OpeningHour;
    sunday: OpeningHour;
}

export interface OpeningHoursState {
    message: string;
}


/**
 * @returns {OpeningHour} Returns the OpeningHours of given day
 * @param openingHours Object with OpeningHours of every day
 * @param dayOfTheWeek Weekday (1 = Monday, 7 = Sunday)
 */
export function getOpeningHourOfWeekday(openingHours: OpeningHours, dayOfTheWeek: number): OpeningHour {

    switch (dayOfTheWeek) {
        case 1: {
            return openingHours.monday;
        }
        case 2: {
            return openingHours.tuesday;
        }
        case 3: {
            return openingHours.wednesday;
        }
        case 4: {
            return openingHours.thursday;
        }
        case 5: {
            return openingHours.friday;
        }
        case 6: {
            return openingHours.saturday;
        }
        case 7: {
            return openingHours.sunday;
        }
        default: {
            throw new Error("Not a valid day of the Week" + dayOfTheWeek);
        }
    }

}