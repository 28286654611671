import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Subscription} from "../data/subscription/Subscription";

export interface SubscriptionState {
    loading: boolean;
    subscriptions: Subscription[];
}

const initialState: SubscriptionState = {
    loading: false,
    subscriptions: []
}

const subscriptionSlice = createSlice({
    name: 'subscriptions',
    initialState: initialState,
    reducers: {
        loading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        replaceAll(state, action: PayloadAction<Subscription[]>) {
            state.subscriptions = action.payload;
        },
        markContact(state, action: PayloadAction<{clientId: string, isContact: boolean}>) {
            const subscription = state.subscriptions.filter(s => s.clientId === action.payload.clientId).shift();
            if(subscription) {
                subscription.isContact = action.payload.isContact;
            }
        }
    }
})

export const subscriptionActions = subscriptionSlice.actions;
export default subscriptionSlice.reducer;