import './AddressForm.scss';

import {useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import {Col, Row} from "react-bootstrap";
import {StateUtil} from "../../data/address/StateUtil";
import {Address} from "../../data/address/Address";
import Dropdown from "../../ui/dropdown/Dropdown";
import {AddressService} from "../../data/address/Address.service";
import Spinner from "../../ui/spinner/Spinner";

export interface AddressFormProps {
    address?: Address;
    onChanged: (address: Address) => void;
}

const AddressForm = (props: AddressFormProps) => {

    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [zip, setZip] = useState<number | undefined>(undefined);
    const [city, setCity] = useState('');
    const [state, setState] = useState('')
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [loadingState, setLoadingState] = useState(false);

    const formRef = useRef(null);
    const cantons = StateUtil.swissCantons();

    useEffect(() => {
        if (props.address) {
            setStreet(props.address.street);
            setStreetNumber(props.address.streetNumber);
            setZip(props.address.zip);
            setState(props.address.state);
            setCity(props.address.city);
            setLatitude(props.address.coordinate?.latitude as string);
            setLongitude(props.address.coordinate?.longitude as string);
        }
    }, []);

    useEffect(() => {
        props.onChanged(mapFromState());
    }, [street, streetNumber, zip, city, state, latitude, longitude]);

    function mapFromState(): Address {
        return {
            street: street ? street : '',
            streetNumber: streetNumber ? streetNumber : '',
            zip: zip ? zip : 0,
            city: city ? city : '',
            state: state ? state : '',
            coordinate: {
                latitude: latitude ? latitude : '',
                longitude: longitude ? longitude : ''
            }
        }
    }

    function onZipChanged(event: any) {
        const newZip = event.target.value;
        setZip(parseInt(newZip));
        if (newZip && String(newZip).length === 4) {
            setLoadingState(true)
            AddressService.searchCanton(newZip).then(canton => {
                setLoadingState(false)
                setState(canton ? canton : '');
            }).catch(() => {
                setLoadingState(false);
                setState('');
            });
        }
    }

    return (
        <>
            <Form ref={formRef}>
                <Row>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='street'
                            placeholder={'Strasse*'}
                            onChange={(event) => setStreet(event.target.value)}
                            value={street}
                            required>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='streetNumber'
                            placeholder={'Nr.'}
                            onChange={(event) => setStreetNumber(event.target.value)}
                            value={streetNumber}>
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='zip'
                            type={'number'}
                            placeholder={'Plz*'}
                            onChange={onZipChanged}
                            value={zip ?? ""}
                            required>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='city'
                            placeholder={'Ort*'}
                            onChange={(event) => setCity(event.target.value)}
                            value={city}
                            required>
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-3 width-50-percent">
                        {loadingState && <Spinner/>}
                        {!loadingState && <Dropdown
                            showRequired={!state}
                            value={state}
                            values={cantons}
                            onSelected={(code) => setState(code)}
                        />}
                    </Form.Group>
                </Row>
                <Row className={'me-margin-md-top'}>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='latitude'
                            placeholder={'Latitude*'}
                            onChange={(event) => setLatitude(event.target.value)}
                            value={latitude}
                            required>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Control
                            id='longitude'
                            placeholder={'Longitude*'}
                            onChange={(event) => setLongitude(event.target.value)}
                            value={longitude}
                            required>
                        </Form.Control>
                    </Form.Group>
                </Row>
            </Form>
        </>
    )

}

export default AddressForm;