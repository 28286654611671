import './info-list-box.scss';

import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface InfoListBoxProps {
    texte: string[];
    listContainerStyle?: any;
    iconPlacement?: string;
    children?:any;
}


const InfoListBox = (props: InfoListBoxProps) => {

    const [showListBox, setShowListBox] = useState(false);
    let placementCls;
    if (props.iconPlacement === 'top-right') {
        placementCls = 'top-right';
    } else if (props.iconPlacement === 'bottom-right') {
        placementCls = 'bottom-right';
    }

    const activeCls = showListBox ? ' active' : '';

    useEffect(() => {
        setShowListBox(!props.iconPlacement);
    }, [])

    return (
        <>
            {props.iconPlacement &&
                <div onClick={() => setShowListBox(!showListBox)}
                     className={'me-info-list-box-icon ' + placementCls + activeCls}>
                    <FontAwesomeIcon icon={'circle-question'}/>
                </div>
            }
            {showListBox &&
                <div style={props.listContainerStyle} className={'me-flex-column me-info-list-box ' + placementCls}>
                    <ul>
                        {props.texte.map(text =>
                            <li key={text}>{text}</li>
                        )}
                    </ul>
                    {props.children && <div className={'me-margin-md'}>{props.children}</div>}
                </div>
            }
        </>
    )

}

export default InfoListBox;