import {Alert, Button, Form, Modal} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import "./SpecialOpeningHours.scss";
import {CompanyProfilUpdate} from "../../../../data/company/Company";
import {CompanyService} from "../../../../data/company/Company.service";
import DatesTimesEditor from "../../../../ui/date/datetime/DatesTimesEditor";
import {DateTime} from "../../../../data/base/DateTime";
import {companyActions} from "../../../../store/company";

export interface SpecialOpeningHoursSettingsProps {
    showModal: boolean;
    onSave: () => void;
    onCancel: () => void;
}

const SpecialOpeningHourPage = (props: SpecialOpeningHoursSettingsProps) => {


    const dispatch = useDispatch();
    const [errorText, setErrorText] = useState('');
    const company = useSelector((state: RootState) => state.company?.data);

    const [isSaving, setSaving] = useState(false);
    const [error, setError] = useState(false);
    const [allSpecialOpeningHours, setAllSpecialOpeningHours] = useState<DateTime[]>(company?.specialOpeningHours as any);


    useEffect(() => {
        setAllSpecialOpeningHours(allSpecialOpeningHours);
    }, [allSpecialOpeningHours])


    function resetForm() {
        setAllSpecialOpeningHours(company?.specialOpeningHours as any);
        setError(false);
        setErrorText('');
    }


    function cancel() {
        resetForm();
        props.onCancel();
    }

    function allValid(): boolean {
        if (allSpecialOpeningHours.length === 0) {
            return true;
        }
        return !allSpecialOpeningHours.some(specialOpeningHour => {
            if (specialOpeningHour.date === '' || specialOpeningHour.from === '' || specialOpeningHour.to === '') {
                setErrorText('Bitte füllen Sie alle Felder aus.');
                setError(true);
                return true;
            }
            return false;
        });

    }

    function save() {
        setSaving(true);
        setErrorText('');
        setError(false);

        if (!allValid()) {
            setSaving(false);
            return;
        }
        const companyProfileUpdate: CompanyProfilUpdate = {
            id: company?.id as string,
            company: company?.company as string,
            email: company?.email as string,
            telefon: company?.telefon as string,
            image: company?.image as string,
            specialOpeningHours: allSpecialOpeningHours,
        }
        CompanyService.update(companyProfileUpdate)
            .then(() => {
                CompanyService.read(company?.id as string).then(company => {
                    dispatch(companyActions.setCompany(company));
                    setSaving(false);
                    props.onSave();
                })
            })
            .catch(error => {
                setErrorText(error);
                setError(true);
            });
        setSaving(false);
    }

    function createForm() {
        return (
            <div className={'me-special-opening-hours'}>
                <Form.Group>
                    <Form.Label> Ich habe an folgenden Tagen zusätzlich geöffnet: </Form.Label>
                    <DatesTimesEditor
                        values={allSpecialOpeningHours}
                        onChange={(datesTimes) => setAllSpecialOpeningHours(datesTimes)}
                    />
                    {error &&
                        <Alert className='settings-error' variant='danger'>
                            {errorText}
                        </Alert>
                    }
                </Form.Group>

            </div>
        )
    }

    return <>
        <Modal show={props.showModal}
               backdrop="static"
               onHide={cancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Sonderöffnungszeiten</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createForm()}</Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    </>

}

export default SpecialOpeningHourPage;