import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface ConfirmState {
    show: boolean,
    data: ConfirmPayload
}

const initialState: ConfirmState = {
    show: false,
    data: {
        confirmed: false,
        usage: '',
        text: '',
        object: {}
    }
}

export interface ConfirmPayload {
    confirmed: boolean,
    usage: string,
    text: string,
    object: any
}

const confirmSlice = createSlice({
    name: 'confirm',
    initialState: initialState,
    reducers: {
        show(state, action: PayloadAction<ConfirmPayload>) {
            state.show = true;
            state.data = action.payload;
        },
        cancel(state) {
            state.show = false;
            state.data = {
                confirmed: false,
                usage: state.data.usage,
                text: state.data.text,
                object: null
            }
        },
        ok(state) {
            state.show = false;
            state.data = {
                confirmed: true,
                usage: state.data.usage,
                text: state.data.text,
                object: state.data.object
            }
        }
    }
})

export const confirmActions = confirmSlice.actions;
export default confirmSlice.reducer;