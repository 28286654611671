import './PostPage.scss';

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import PostTile from "../../components/post/tile/PostTile";
import Spinner from "../../ui/spinner/Spinner";
import IconButton from "../../ui/icon-button/IconButton";
import {useState} from "react";
import CreatePostForm from "../../components/post/create/CreatePostForm";
import {ToggleButton} from "react-bootstrap";
import {DateUtil} from "../../data/DateUtil";
import {isPostActive} from "../../data/post/PostUtil";
import {Post} from "../../data/post/Post";
import {PostService} from "../../data/post/Post.service";
import {postsActions} from "../../store/posts";

const PostPage = () => {

    const dispatch = useDispatch();

    const companyId = useSelector((state: RootState) => state.account.data?.companyId) as string;
    const gemeindeId = useSelector((state: RootState) => state.account.data?.gemeindeId) as string;

    const posts = useSelector((state: RootState) => state.posts.posts);
    const loading = useSelector((state: RootState) => state.posts.loading);

    const [postContext, setPostContext] = useState<Post>();
    const [showMutation, setShowMutation] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [showOnlyToday, setShowOnlyToday] = useState(false);

    function onEditCLicked(postId: string) {
        const post = posts.find(p => p.postId === postId) as unknown as Post;
        setPostContext(post);
        setShowMutation(() => true);
    }

    function onNewClicked() {
        setPostContext(undefined);
        setShowMutation(() => true);
    }

    function refreshNotifications() {
        dispatch(postsActions.loading(true));
        PostService.search(companyId ? companyId : gemeindeId)
            .then((posts) => {
                dispatch(postsActions.loading(false));
                dispatch(postsActions.replaceAll(posts))
            });
    }

    return (
        <>
            {loading && <Spinner></Spinner>}
            {!loading &&
                <div className='me-page-container'>
                    <div className='me-post-page-menu'>
                        <div className={'me-flex-grow'}>
                            <IconButton iconName={'add'} onClick={onNewClicked}/>
                        </div>
                        <div className={'me-flex-row'}>
                            <ToggleButton
                                className={'me-badge-button' + (showOnlyToday ? ' active' : "")}
                                id="toggle-check-showOnlyToday"
                                type="checkbox"
                                variant="light" checked={showOnlyToday} value={"1"}
                                onChange={() => setShowOnlyToday(() => !showOnlyToday)}>
                                Heute
                            </ToggleButton>
                            <ToggleButton
                                className={'me-badge-button' + (showInactive ? ' active' : "")}
                                id="toggle-check-showInactive"
                                type="checkbox"
                                variant="light" checked={showInactive} value={"1"}
                                onChange={() => setShowInactive(() => !showInactive)}>
                                Abgelaufen
                            </ToggleButton>
                        </div>
                    </div>
                    <div className={'me-post-page-list-container'}>
                        {posts
                            .filter(post => showInactive ? true : isPostActive(post))
                            .filter(post => showOnlyToday ? DateUtil.isToday(post.showAt.toString()) : true)
                            .map((post) => (
                                <PostTile
                                    key={post.postId}
                                    post={post}
                                    onEdit={onEditCLicked}
                                    onDeleted={refreshNotifications}
                                    onActivated={refreshNotifications}
                                />
                            ))}
                    </div>
                </div>}
            <CreatePostForm
                showModal={showMutation}
                initialNotification={postContext}
                onSave={() => {
                    setShowMutation(false);
                    refreshNotifications();
                }}
                onCancel={() => setShowMutation(false)}/>
        </>
    )
}

export default PostPage;