import './Login.scss';
import React, {Fragment, useState} from "react";
import {AuthService} from "../../data/auth/Auth.service";
import {LoginData} from "../../data/auth/LoginData";
import {Alert, Button, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {RouteNames} from "../../route-names";
import {defaultEmail, defaultPasswort} from "../../mock/login";
import Spinner from "../../ui/spinner/Spinner";
import {useDispatch} from "react-redux";
import {accountActions} from '../../store/account';

// @ts-ignore
import LogoAnimatedSVG from "../../images/logo_animated_converted.svg";

const Login = () => {

    const loginScreenSVG: string = require("../../images/login_screen.svg").default
    const [email, setEmail] = useState(defaultEmail);
    const [password, setPassword] = useState(defaultPasswort);
    const [error, setError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    function onPasswordChangeHandler(event: any) {
        setError(false);
        setPassword(event.target.value);
    }

    function onEmailChangeHandler(event: any) {
        setError(false);
        setEmail(event.target.value);
    }

    function onFormSubmitHandler(event: any) {
        event.preventDefault();
        setError(false);
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            setIsLogin(true);
            AuthService.login(new LoginData(email, password))
                .then(account => {
                    dispatch(accountActions.setAccount(account));
                    setIsLogin(false);
                    navigate("/" + RouteNames.CLIENTS)
                })
                .catch(() => {
                    setIsLogin(false);
                    setError(true)
                });
        }
    }

    function signUp() {
        navigate('/' + RouteNames.SIGNUP);
    }

    function resetPassword() {
        navigate('/' + RouteNames.PASSWORD_RESET);
    }

    return (
        <>
            <div className='me-login-container'>
                <img className='me-login-background' src={loginScreenSVG} alt={''}></img>
                <img className='me-login-logo' src={LogoAnimatedSVG} alt={''}></img>
                <div className='me-login-form-container'>
                    <Form className='me-login-form'
                          noValidate
                          validated={validated}
                          onSubmit={onFormSubmitHandler}>
                        <Form.Group className="mb-3">
                            <Form.Label defaultValue={defaultEmail()}>E-Mail</Form.Label>
                            <Form.Control
                                required
                                defaultValue={defaultEmail()}
                                onChange={onEmailChangeHandler}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                required
                                defaultValue={defaultPasswort()}
                                type="password"
                                onChange={onPasswordChangeHandler}/>
                        </Form.Group>
                        {isLogin && <Spinner></Spinner>}
                        {!isLogin &&
                            <Button className='me-login-button' variant={'outline-secondary'}
                                    type={"submit"}> Anmelden </Button>}
                        {error && <Alert className='me-login-alert' variant='danger'>
                            Benutzername und/oder Passwort falsch
                        </Alert>}
                        <img className='me-login-logo-sale' src={require('../../images/sale_logo.png')} alt={''}></img>
                    </Form>
                </div>
                <span className='me-login-info'>
                    <div className='me-login-info-text'>Noch kein Konto?
                        <a className='me-login-to-signup-link' onClick={signUp}>{'Neues Konto >'}</a>
                    </div>
                    <div className='me-login-info-text'>Passwort vergessen?
                        <a className='me-login-to-signup-link' onClick={resetPassword}>{'Zurücksetzen >'}</a>
                    </div>
                </span>
                <div className={'me-agb-impressum-container'}>
                    <a href={"https://onra.ch/me-agb/"} target="_blank"
                       rel="noopener noreferrer">
                        AGB
                    </a>
                    <div className={'me-divider'}>|</div>
                    <a href={"https://onra.ch/impressum/"} target="_blank"
                       rel="noopener noreferrer">
                        Impressum
                    </a>
                </div>
            </div>
        </>

    )

}


export default Login;