import axiosInstance from "../../AxiosInterceptor";
import {SwissCanton} from "./swiss.canton";

export class AddressService {

    public static async searchCanton(zipCode: number): Promise<SwissCanton | null> {
        const response = await axiosInstance.get('address/canton/zip/' + zipCode);
        if (response.data?.canton in SwissCanton) {
            return SwissCanton[response.data?.canton as keyof typeof SwissCanton];
        }
        return null;
    }

}