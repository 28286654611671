import './Dropdown.scss';
import {Dropdown as BootstrapDropdown, DropdownButton} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {DropDirection} from "react-bootstrap/DropdownContext";

export interface DropdownValue {
    code: string,
    label: string
}

interface DropdownProps {
    value: string;
    showRequired: boolean;
    drop?: DropDirection;
    values: DropdownValue[];
    onSelected: (code: string) => void;
}

const Dropdown = (props: DropdownProps) => {

    const [selectedValue, setSelectedValue] = useState<string>(props.value);

    const drop: DropDirection = props.drop ? props.drop : 'down';

    useEffect(() => {
        setSelectedValue(props.value);
    }, [props.value])

    let showRequired = props.showRequired;
    let title = props.values.find(v => v.code === selectedValue)?.label;
    if (!title) {
        showRequired = props.showRequired;
        title = 'Bitte wählen ...';
    }

    function onSelect(value: DropdownValue) {
        setSelectedValue(() => value.code as string);
        props.onSelected(value.code);
    }

    return (
        <DropdownButton title={title} drop={drop}
                        className={`me-dropdown-button ${showRequired ? 'missing' : ''}`}>
            <div className="dropdown-scroll">
                {props.values.map(value => {
                    return <BootstrapDropdown.Item
                        key={value.code}
                        onClick={() => onSelect(value)}>{value.label}
                    </BootstrapDropdown.Item>
                })}
            </div>
        </DropdownButton>
    )


}

export default Dropdown;