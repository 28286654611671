import {OpeningHour, OpeningHoursState} from "./OpeningHour";
import {DateTime} from "../base/DateTime";
import {CompanyProfileState} from "./CompanyProfileState";
import {CompanyWeblinks} from "./weblinks/CompanyWeblinks";
import {Address} from "../address/Address";
import {AddressLocation} from "../address/AddressLocation";

export enum CompanyState {
    VERIFIED,
    NOT_VERIFIED
}

export interface Adresse {
    street: string;
    streetNumber: string;
    zip: string;
    city: string;
    state: string;
}

export interface CompanyProfilUpdate {
    id: string;
    company: string;
    image: string;
    email: string;
    telefon: string;
    specialOpeningHours: DateTime[];
}

export interface Company {
    id: string;
    company: string;
    image: string;
    email: string;
    ort: string;
    plz: string;
    telefon: string;
    weblinks: CompanyWeblinks | null;
    openingHours: OpeningHour[];
    openingHoursState: OpeningHoursState;
    specialOpeningHours: DateTime[];
    state: CompanyState;
    address: Address;
    locations: AddressLocation[];
    profileState: CompanyProfileState;
    source: string;
    credits: number;
}

export function mapCompany(data: any): Company {
    return {
        id: data.id,
        company: data.company,
        email: data.email,
        image: data.image,
        plz: data.plz,
        ort: data.ort,
        telefon: data.telefon,
        openingHours: !data.openingHours ? [] : data.openingHours,
        openingHoursState: {
            message: data.openingHoursState ? data.openingHoursState.message : ''
        },
        specialOpeningHours: !data.specialOpeningHours ? [] : data.specialOpeningHours,
        address: data.adresse,
        locations: data.locations,
        weblinks: data.weblinks,
        profileState: data.profileState,
        state: data.state,
        source: data.source,
        credits: !data.credits ? 0 : data.credits
    };
}

export default Company;