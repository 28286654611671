import {Client, ClientContact} from "./Client";
import {ClientsBulk} from "./ClientsBulk";
import {axiosInstance} from "../../AxiosInterceptor";

export class ClientService {

    public static async readBulk(customerIds: string[]): Promise<ClientsBulk> {
        if (customerIds.length === 0) {
            return Promise.resolve({clients: [], clientsNotFound: []});
        }
        let response = await axiosInstance.post(`clients/bulk`, customerIds);
        const clients = response.data.clients.map(ClientService.mapClient);
        return {
            clients: clients,
            clientsNotFound: response.data.clientsNotFound
        }
    }

    private static mapClient(data: any): Client {
        return {
            id: data.id,
            firstname: data.firstname,
            name: data.name,
            email: data.email,
            contact: ClientService.mapClientContact(data.contact),
            profileImage: data.profileImage
        }
    }

    private static mapClientContact(data: any): ClientContact {
        return {
            zip: data?.zip,
            city: data?.city,
            telefon: data?.telefon,
            birthday: data?.birthday
        }
    }

}