import './AddEventComment.scss';

import React, {useState} from "react";
import {EventService} from "../../../../data/event/Event.service";
import {Button, Form} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import IconButton from "../../../../ui/icon-button/IconButton";


export interface AddEventCommentProps {
    eventId: string;
    companyId: string;
    add: () => void;
}

const AddEventComment = (props: AddEventCommentProps) => {

    const [saving, setSaving] = useState(false);
    const [focus, setFocus] = useState(false);
    const [message, setMessage] = useState('')


    function changeMessage(e: any) {
        setMessage(e.target.value)
    }

    function onAdd() {
        setSaving(true);
        EventService.addComment(props.companyId, props.eventId, message, '').then(() => {
            setSaving(false);
            setMessage('');
            setFocus(false);
            props.add();
        })
    }

    function onCancel() {
        setMessage('');
        setFocus(false);
    }

    return (
        <div className={'me-add-event-comment'}>
            <Form.Group className="mb-3">
                <Form.Control
                    as="textarea" rows={5}
                    value={message}
                    placeholder={'Neuen Kommentar erfassen ...'}
                    onFocus={() => setFocus(true)}
                    onChange={changeMessage}/>
            </Form.Group>
            {saving && <Spinner></Spinner>}
            {focus && <div className='footer'>
                <IconButton
                    className={'green-hover'}
                    disabled={message.length === 0}
                    iconName={'check'}
                    onClick={onAdd}/>
                <IconButton
                    className={'red-hover'}
                    marginLeft={5}
                    iconName={'xmark'}
                    onClick={onCancel}/>
            </div>}
        </div>
    )

}


export default AddEventComment;