import './GemeindeAboutEdit.scss';

import {Alert, Button, Form, Modal} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import IconButton from "../../../../ui/icon-button/IconButton";
import {GemeindeAboutService} from "../../../../data/gemeinde/GemeindeAbout.service";
import {Gemeinde} from "../../../../data/gemeinde/Gemeinde";
import {GemeindeAbout, GemeindeAboutItem} from "../../../../data/gemeinde/GemeindeAbout";
import GemeindeAboutItemEditRow from "./GemeindeAboutItemEditRow";
import GemeindeAboutItemEditPopup from "./GemeindeAboutItemEditPopup";
import {CompanyAboutItem} from "../../../../data/company/about/CompanyAbout";

export interface GemeindeAboutEditProps {
    onSave: () => void;
    onCancel: () => void;
}

const GemeindeAboutEdit = (props: GemeindeAboutEditProps) => {

    const gemeinde = useSelector((state: RootState) => state.gemeinde?.data) as Gemeinde;
    const [aboutMainDescription, setAboutMainDescription] = useState<string>('');
    const [aboutItems, setAboutItems] = useState<GemeindeAboutItem[]>([]);
    const [showNewItem, setShowNewItem] = useState(false);
    const [showNewItemDefault, setShowNewItemDefault] = useState<GemeindeAboutItem | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        loadAbout();
    }, [])

    function loadAbout() {
        setIsLoading(true);
        GemeindeAboutService.search(gemeinde.id).then(about => {
            setIsLoading(false);
            if (about) {
                setAboutMainDescription(about.description);
                setAboutItems(about.items);
            }
        });
    }

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function resetForm() {
        setErrorText('');
    }

    async function save() {
        try {
            setErrorText('');
            setSaving(true);
            const about: GemeindeAbout = {
                gemeindeId: gemeinde.id,
                description: aboutMainDescription,
                items: aboutItems,
            }
            await GemeindeAboutService.update(gemeinde.id, about)
            setSaving(false);
            cancel();
        } catch (e: any) {
            setSaving(false)
            if (e.response?.data === 'request entity too large') {
                setErrorText('Die Dateien sind zu gross, bitte reduzieren Sie die Grösse!');
            } else {
                setErrorText('Fehler bei Speicherung...');
            }
        }
    }

    function removeItem(id: string) {
        const copiedItems = [...aboutItems];
        const index = aboutItems.map(item => item.id).indexOf(id);
        copiedItems.splice(index, 1);
        setAboutItems(copiedItems);
    }

    function moveItemUp(id: string) {
        const item = aboutItems.find(item => item.id === id) as GemeindeAboutItem;
        const fromIndex = aboutItems.map(q => q.id).indexOf(id);
        const copiedItems = aboutItems.filter(q => q.id !== id);
        const newIndex = fromIndex - 1;
        copiedItems.splice(newIndex, 0, item)
        setAboutItems(copiedItems);
    }

    function onItemClicked(item: GemeindeAboutItem) {
        setShowNewItem(true);
        setShowNewItemDefault(item);
    }

    function onNewItemClicked() {
        setShowNewItemDefault(null);
        setShowNewItem(true);
    }

    function add(item: GemeindeAboutItem) {
        const index = aboutItems.map(item => item.id).indexOf(item.id);
        if (index > -1) {
            aboutItems[index] = item;
        } else {
            const copiedItems = [...aboutItems, item];
            setAboutItems(copiedItems);
        }
        setShowNewItemDefault(null);
        setShowNewItem(false);
    }

    function onAboutMainDescriptionChanged(event: any) {
        setErrorText('');
        setAboutMainDescription(event.target.value)
    }

    return <>
        <Modal show={true}
               backdrop={true}
               onHide={cancel}
               size={'lg'}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>Über uns bearbeiten</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <Spinner/>}
                {!isLoading && <div className={'me-edit-gemeinde-about'}>
                    <Form.Group className="mt-3">
                        <Form.Control
                            as="textarea" rows={12}
                            maxLength={1000}
                            placeholder={'Über uns... '}
                            value={aboutMainDescription}
                            onChange={onAboutMainDescriptionChanged}>
                        </Form.Control>
                        <div className={'me-max-character-indicator'}>{aboutMainDescription?.length}/1000</div>
                    </Form.Group>
                    <div className={'me-edit-gemeinde-about-add-button'}>
                        <IconButton
                            disabled={aboutItems.length > 4}
                            buttonLabel={'Bild'} iconName={'camera'} onClick={onNewItemClicked}
                        />
                    </div>
                    {
                        aboutItems.map((item, index) => {
                            return <div key={item.id} className={'me-flex-row me-margin-lg-bottom'}>
                                <GemeindeAboutItemEditRow
                                    number={++index}
                                    item={item}
                                    onClick={item => onItemClicked(item)}
                                    onRemove={() => removeItem(item.id)}
                                    onMoveUp={() => moveItemUp(item.id)}
                                />
                            </div>
                        })
                    }
                    <div className={'me-max-character-indicator left'}>{aboutItems.length}/5</div>
                </div>}
                {errorText && <Alert variant='danger'>{errorText}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
            {showNewItem &&
                <GemeindeAboutItemEditPopup
                    defaultItem={showNewItemDefault}
                    addItem={item => add(item)}
                    close={() => setShowNewItem(false)}
                />
            }
        </Modal>
    </>
}

export default GemeindeAboutEdit;