import './CreatePostForm.scss';

import React, {useEffect, useRef, useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import Spinner from "../../../ui/spinner/Spinner";
import {Post, PostState} from "../../../data/post/Post";
import ImageSelector from "../../../ui/image-selector/ImageSelector";
import {PostService} from "../../../data/post/Post.service";
import InfoListBox from "../../../ui/info-box/info-list-box";
import Label from "../../../ui/form/Label";
import ExternalLink from "../../../ui/link/ExternalLink";
import {DateUtil} from "../../../data/DateUtil";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";


export interface CreateNotificationFormProps {
    showModal: boolean;
    initialNotification?: Post;
    onSave: () => void;
    onCancel: () => void;
}

const CreatePostForm = (props: CreateNotificationFormProps) => {

    const formRef = useRef(null);
    const companyId = useSelector((state: RootState) => state.company?.data?.id) as string;
    const gemeindeId = useSelector((state: RootState) => state.gemeinde?.data?.id) as string;


    const [errorMessage, setErrorMessage] = useState('');
    const [validated, setValidated] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [image, setImage] = useState('');
    const [linkUrl, setLinkUrl] = useState('');
    const [showAt, setShowAt] = useState(DateUtil.simpleTodayString());

    let initial = props.initialNotification;

    useEffect(() => {
        if (props.showModal && initial) {
            setTitle(initial.title);
            setText(initial.text);
            setImage(initial.image);
            setLinkUrl(initial.linkUrl);
            setShowAt(DateUtil.asFormDateString(initial.showAt));
        }
        // eslint-disable-next-line
    }, [props.showModal])

    function onTitleChanged(event: any) {
        setErrorMessage('');
        setTitle(event.target.value);
    }

    function onTextChanged(event: any) {
        setErrorMessage('');
        setText(event.target.value);
    }

    function onShowAtChanged(event: any) {
        setErrorMessage('');
        setShowAt(event.target.value);
    }

    function onImageChanged(image: string) {
        setErrorMessage('');
        setImage(image);
    }

    function onLinkUrlChanged(url: string) {
        setErrorMessage('');
        setLinkUrl(url);
    }

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function saved() {
        resetForm();
        props.onSave();
    }

    function resetForm() {
        setErrorMessage('');
        setSaving(() => false);
        setValidated(() => false);
        setShowAt(() => DateUtil.simpleTodayString());
        setTitle(() => '');
        setText(() => '');
        setLinkUrl(() => '');
        setImage('')
    }

    function save() {
        setErrorMessage('');
        if (isValid()) {
            setSaving(true);
            if (initial) {
                PostService.update(initial.postId, mapPost())
                    .then(saved)
                    .catch(error);
            } else {
                PostService.add(companyId ? companyId : gemeindeId, mapPost())
                    .then(saved)
                    .catch(error);
            }
        }
    }

    function isValid(): boolean {
        setValidated(true);
        const form = formRef.current as any;
        return form.checkValidity() === true;
    }

    function error(e: any) {
        setErrorMessage(e.response ? e.response.data : e)
        setSaving(false);
    }

    function mapPost(): Post {
        return {
            postId: "",
            image: image,
            linkUrl: linkUrl,
            showAt: showAt,
            state: PostState.CREATED,
            text: text,
            title: title,
            likes: [],
        }
    }

    function createForm() {
        return (
            <Form ref={formRef} id='createNotificationForm'
                  noValidate
                  validated={validated}>
                <div className={'me-flex-column me-flex-grow'}>
                    <InfoListBox iconPlacement={'top-right'} texte={[
                        'Erstelle einen Post mit einem Bild und kurzen Text.',
                        "Erst durch veröffentlichen, wird ein Post angezeigt",
                        'Willst du den Post einplanen, verwende "Anzeigen ab" die Post werden erst ab diesem Datum sichtbar.',
                        'Falls du einen Link erfasst, kann dieser geöffnet werden z.B. für weiter Informationen.'
                    ]}/>
                    <div className='me-notification-image-container mb-3'>
                        <ImageSelector
                            defaultImage={image}
                            imageMaxSizeMB={0.5}
                            imageSelected={onImageChanged}
                        />
                    </div>
                    <Form.Group className="mb-1">
                        <Form.Control
                            required
                            placeholder={'Titel'}
                            value={title}
                            onChange={onTitleChanged}/>
                    </Form.Group>
                    <Form.Group className={'me-flex-column me-flex-grow'}>
                        <Form.Control
                            className={'me-notification-text-input'}
                            as="textarea"
                            value={text}
                            maxLength={200}
                            rows={5}
                            placeholder={'Beschreibung'}
                            required
                            onChange={onTextChanged}/>
                    </Form.Group>
                    <Form.Label
                        className={'me-notification-text-count'}>{text.length}/200</Form.Label>
                    <div className={'mb-3'}>
                        <Label label={'Link'} icon={'globe'}/>
                        <ExternalLink value={linkUrl} onChanged={onLinkUrlChanged}/>
                    </div>
                    <Form.Group className={'mb-3'}>
                        <Label label={'Anzeigen ab'} icon={'clock'}/>
                        <Form.Control
                            type="date"
                            required={true}
                            min={DateUtil.simpleTodayString()}
                            value={showAt}
                            onChange={onShowAtChanged}
                        />
                    </Form.Group>
                </div>
            </Form>
        )
    }

    return (
        <Modal show={props.showModal}
               className={'me-create-notification-form-modal'}
               backdrop="static"
               onHide={cancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Post erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createForm()}</Modal.Body>
            <Modal.Footer>
                <div className={'me-flex-column me-flex-grow'}>
                    {errorMessage &&
                        <Alert
                            className='me-save-notification-alert'
                            variant='danger'>{errorMessage}
                        </Alert>
                    }
                    <div className={'me-flex-row me-justify-flex-end'}>
                        {isSaving && <Spinner></Spinner>}
                        {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                        {!isSaving && <Button variant="primary" onClick={save}> Speichern </Button>}
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )

}

export default CreatePostForm;
