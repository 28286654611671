import './PostTile.scss'

import {Card} from "react-bootstrap";
import {Post} from "../../../data/post/Post";
import {DateUtil} from "../../../data/DateUtil";
import IconButton from "../../../ui/icon-button/IconButton";
import React, {useState} from "react";
import {PostService} from "../../../data/post/Post.service";
import Spinner from "../../../ui/spinner/Spinner";
import ClientsOverview from "../../client/overview/ClientsOverview";
import OpenModalButton from "../../../ui/open-model-button/open-modal-button";
import {StringUtil} from "../../../data/StringUtil";

export interface PostTileProps {
    post: Post;
    onDeleted: () => void;
    onActivated: () => void;
    onEdit: (string: string) => void;
}

const PostTile = (props: PostTileProps) => {

    const [activating, setActivating] = useState(false);
    const post = props.post;
    const likesClientId = post.likes.map(f => f.clientId);

    function deletePost(e: any) {
        e.stopPropagation();
        PostService
            .delete(post.postId)
            .then(() => {
                props.onDeleted();
            })
    }

    function activatePost(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        setActivating(true);
        PostService
            .activate(post.postId)
            .then(() => props.onActivated());
    }

    function editPost(e: any) {
        e.stopPropagation();
        props.onEdit(props.post.postId);
    }

    return (
        <Card className={'post-card'}>
            <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'me-flex-row me-flex-grow'}>
                    <div className={'me-flex-column me-flex-grow'}>
                        <div className={'me-post-tile-title'}>{post.title}</div>
                        <div className={'me-post-tile-date'}>{DateUtil.displayShortNoTime(post.showAt)}</div>
                        <div className={'me-post-tile-text'}>{StringUtil.cut(post.text, 30)}</div>
                    </div>
                    {post.image && <Card.Img className={'post-card-image'} src={post.image} alt=""/>}
                </div>
                {activating && <Spinner small={true}></Spinner>}
                {!activating && <div className={'me-post-state-container'}>
                    {post.state === 'CREATED' &&
                        <div className={'me-activate-post-button'}
                             onClick={activatePost}>Veröffentlichen</div>}
                    {post.state === 'ACTIVATED' &&
                        <div className={'me-post-state active'}>{'Veröffentlicht'}</div>}
                </div>}
                <div className={'me-post-card-acions'}>
                    <div className={'me-post-card-acions-left'}>
                        <OpenModalButton
                            icon={'star'}
                            buttonLabel={likesClientId.length as unknown as string}
                            modalTitle={'Likes'}>
                            <ClientsOverview clientIds={likesClientId}/>
                        </OpenModalButton>
                    </div>
                    <div className={'me-post-card-acions-right'}>
                        <IconButton iconName={'edit'} onClick={editPost}/>
                        <IconButton className={'red-hover'} marginLeft={10}
                                    iconName={'trash-can'} onClick={deletePost}/>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default PostTile;