import {Subscription} from "./Subscription";
import {axiosInstance} from "../../AxiosInterceptor";

export class SubscriptionService {

    public static async search(companyId: string): Promise<Subscription[]> {
        const response = await axiosInstance.get(`companies/${companyId}/subscriptions`);
        return response.data.map(SubscriptionService.mapSubscription);
    }

    public static async markContact(clientId: string, companyId: string, isContact: boolean): Promise<void> {
        const data = {
            clientId: clientId,
            companyId: companyId,
            isContact: isContact
        }
        await axiosInstance.put(`companies/${companyId}/subscriptions/contact`, data);
    }

    private static mapSubscription(data: any): Subscription {
        return {
            clientId: data.clientId,
            isContact: data.isContact
        }
    }
}