import './CreateSurveyQuestions.scss';

import {SURVEY_CONST, SurveyQuestion} from "../../../data/survey/Survey";
import React, {useEffect, useRef, useState} from "react";
import CreateAnswersList from "./CreateAnswersList";
import {Form} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import IconButton from "../../../ui/icon-button/IconButton";

export interface CreateSurveyAnswerProps {
    initialQuestions: SurveyQuestion[];
    onIsEditing: (isEditing: boolean) => void;
    onQuestionsChanged: (questions: SurveyQuestion[]) => void;
}

const CreateSurveyQuestions = (props: CreateSurveyAnswerProps) => {

    const [questions, setQuestions] = useState(props.initialQuestions);
    const [openedQuestions, setOpendendQuestions] = useState(['']);
    const [newQuestionText, setNewQuestionText] = useState('');
    const [changingQuestion, setChangingQuestion] = useState<SurveyQuestion>();
    const [changeQuestionText, setChangeQuestionText] = useState('');

    useEffect(() => {
        setQuestions(props.initialQuestions)
    }, [props.initialQuestions]);

    const changeQuestionTextinputRef = useRef<HTMLInputElement>(null);

    function onChangeNewQuestionText(e: any) {
        setNewQuestionText(e.target.value)
    }

    function onAddNewQuestion() {
        if (newQuestionText.length > 0) {
            let newQuestions = [...questions];
            newQuestions.push({
                question: newQuestionText,
                answers: []
            })
            setQuestions(newQuestions);
            setNewQuestionText('')
            setOpendendQuestions([String(questions.length)]);
            props.onQuestionsChanged(newQuestions);
        }
    }

    function onCancelNewQuestion() {
        setNewQuestionText('');
    }

    function moveQuestionUp(e: any, question: SurveyQuestion) {
        e.stopPropagation();
        const fromIndex = questions.map(q => q.question).indexOf(question.question);
        const moved = questions.filter(q => q.question !== question.question);
        const newIndex = fromIndex - 1;
        moved.splice(newIndex, 0, question)
        setQuestions(moved);
        if (openedQuestions.length > 0) {
            setOpendendQuestions([String(newIndex)])
        }
    }

    function deleteQuestion(e: any, question: SurveyQuestion) {
        e.stopPropagation();
        let modifiedQuestions = questions.filter(q => q.question !== question.question);
        setQuestions(modifiedQuestions);
        props.onQuestionsChanged(modifiedQuestions);
    }


    function onMarkQuestionChanging(e: any, question: SurveyQuestion) {
        e.stopPropagation();
        setChangingQuestion(question);
        setChangeQuestionText(question.question);
        props.onIsEditing(true);
    }

    function onQuestionTextChanged(e: any) {
        setChangeQuestionText(e.target.value);
        setTimeout(() => changeQuestionTextinputRef.current?.focus()); // hack because we render input field and lose focus
    }

    function onConfirmChangeQuestionText(e: any, question: SurveyQuestion) {
        e.stopPropagation();
        question.question = changeQuestionText;
        setChangingQuestion(undefined);
        setChangeQuestionText('');
        props.onIsEditing(false);
    }

    function onCancelChangeQuestionText(e: any) {
        e.stopPropagation();
        setChangingQuestion(undefined);
        setChangeQuestionText('');
        props.onIsEditing(false);
    }

    const QuestionHeaderEdit = (props: any) => {
        const question = props.question;
        const allowUp = questions.map(q => q.question).indexOf(question.question) > 0;
        const isQuestionChanging = changingQuestion === question;
        return <div className={isQuestionChanging ? 'me-question-header me-flex-grow' : 'me-question-header'}>
            {isQuestionChanging &&
                <div className={'me-change-question'}>
                    <Form.Control
                        className='me-change-question-input'
                        ref={changeQuestionTextinputRef}
                        value={changeQuestionText}
                        onClick={(e) => e.stopPropagation()}
                        onChange={onQuestionTextChanged}/>
                    <div className='me-change-question-actions'>
                        <IconButton
                            iconName={'xmark'}
                            className={'no-border small'}
                            onClick={(e) => onCancelChangeQuestionText(e)}/>
                        <IconButton
                            iconName={'check'}
                            className={'green-hover no-border small'}
                            onClick={(e) => onConfirmChangeQuestionText(e, question)}/>
                    </div>
                </div>}
            {!isQuestionChanging && <div className='me-question-header-text'>{question.question}</div>}
            {!changingQuestion &&
                <div>
                    <IconButton
                        marginLeft={10}
                        iconName={'edit'}
                        className={'no-border small'}
                        onClick={(e) => onMarkQuestionChanging(e, question)}/>
                    {allowUp && <IconButton iconName={'arrow-up'}
                                            className={'no-border small'}
                                            onClick={(e) => moveQuestionUp(e, question)}/>}
                    <IconButton iconName={'trash-can'}
                                className={'red-hover no-border small'}
                                onClick={(e) => deleteQuestion(e, question)}/>
                </div>
            }

        </div>
    }

    return (
        <>
            <Accordion
                alwaysOpen
                onSelect={(key) => !changingQuestion ? setOpendendQuestions(key as string[]) : ''}
                activeKey={openedQuestions}
                className='me-survey-create-question-accordion'>
                {
                    questions.map((question, index) => {
                        return <Accordion.Item key={question.question} eventKey={String(index)}>
                            <Accordion.Header>
                                <QuestionHeaderEdit question={question}/>
                            </Accordion.Header>
                            <Accordion.Body>
                                <CreateAnswersList
                                    onAnswersChanged={answers => question.answers = answers}
                                    question={question}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    })
                }
            </Accordion>
            {questions.length <= SURVEY_CONST.MAX_QUESTIONS && <Form.Group className="mt-3 mb-3">
                <Form.Control
                    className='me-new-question-input'
                    isValid={true}
                    value={newQuestionText}
                    placeholder={'Frage erfassen ...'}
                    onChange={onChangeNewQuestionText}/>
            </Form.Group>}
            {questions.length <= SURVEY_CONST.MAX_QUESTIONS && <div className='footer'>
                <IconButton
                    className={'green-hover'}
                    invisible={newQuestionText.length === 0}
                    iconName={'check'}
                    onClick={onAddNewQuestion}/>
                <IconButton
                    className={'red-hover'}
                    marginLeft={5}
                    invisible={newQuestionText.length === 0}
                    iconName={'xmark'}
                    onClick={onCancelNewQuestion}/>
            </div>}
        </>
    )
}

export default CreateSurveyQuestions;