import {Alert, Button, Col, Modal} from "react-bootstrap";
import Spinner from "../../../../ui/spinner/Spinner";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {Gemeinde, UpdateGemeindeProfile} from "../../../../data/gemeinde/Gemeinde";
import {GemeindeService} from "../../../../data/gemeinde/Gemeinde.service";
import {gemeindeActions} from "../../../../store/gemeinde";
import Form from "react-bootstrap/Form";
import ExternalLink from "../../../../ui/link/ExternalLink";

export interface GemeindeProfileEditProps {
    onSaved: () => void;
    onCancel: () => void;
}

const GemeindeProfileEdit = (props: GemeindeProfileEditProps) => {

    const formRef = useRef(null);
    const dispatch = useDispatch();
    const gemeinde = useSelector((state: RootState) => state.gemeinde?.data) as Gemeinde;

    const [isSaving, setSaving] = useState(false);
    const [error, setError] = useState('');
    const [changes, setChanges] = useState(false);

    const [website, setWebsite] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        setWebsite(gemeinde.website);
        setEmail(gemeinde.email);
        setPhone(gemeinde.phone);
    }, []);

    useEffect(() => {
        setChanges(hasChanges);
    }, [website, email, phone]);

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function resetForm() {
        setError('');
    }

    function save() {
        const form = formRef.current as any;
        setError('');
        setSaving(true);

        if (!website) {
            setError('Eine Website ist erforderlich');
            setSaving(false);
            return;
        }

        if (form.checkValidity() === false) {
            setError('Bitte alle Mussfelder ausfüllen');
            setSaving(false);
            return;
        }

        function mapGemeindeProfile(): UpdateGemeindeProfile {
            return {
                website: website,
                email: email,
                phone: phone
            }
        }

        const updateGemeindeProfile = mapGemeindeProfile();
        GemeindeService.updateProfile(gemeinde.id, updateGemeindeProfile)
            .then(() => {
                dispatch(gemeindeActions.loading(true));
                GemeindeService.read(gemeinde.id).then(gemeinde => {
                    dispatch(gemeindeActions.loading(false));
                    dispatch(gemeindeActions.setGemeinde(gemeinde))
                    props.onSaved();
                });
            })
            .catch((error) => {
                    setSaving(false);
                    setError(error.message);
                }
            );
    }

    function hasChanges(): boolean {
        return gemeinde.website !== website ||
            gemeinde.email !== email ||
            gemeinde.phone !== phone;
    }

    function createGemeindeProfileForm() {
        return <div className={'me-flex-column'}>
            <Form ref={formRef} className={'me-flex-grow me-margin-md'}>
                <Form.Group>
                    <Form.Label>Website*</Form.Label>
                    <ExternalLink value={website} onChanged={(value) => setWebsite(value)}/>
                </Form.Group>
                <Form.Label className="mt-3">Email*</Form.Label>
                <Form.Group>
                    <Form.Control
                        id='email'
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                        required={true}>
                    </Form.Control>
                </Form.Group>
                <Form.Label className="mt-3">Telefon</Form.Label>
                <Form.Group as={Col}>
                    <Form.Control
                        id='phone'
                        onChange={(event) => setPhone(event.target.value)}
                        value={phone}>
                    </Form.Control>
                </Form.Group>
            </Form>
            {error && <Alert variant='danger'>{error}</Alert>}
        </div>
    }

    return <>
        <Modal show={true}
               backdrop="static"
               onHide={cancel}
               size={'lg'}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Profil</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createGemeindeProfileForm()}</Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button disabled={!changes} variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    </>

}

export default GemeindeProfileEdit;