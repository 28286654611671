import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import AccountLogo from "../profil-logo/AccountLogo";
import React from "react";
import {Gemeinde} from "../../../data/gemeinde/Gemeinde";
import {GemeindeService} from "../../../data/gemeinde/Gemeinde.service";
import {gemeindeActions} from "../../../store/gemeinde";
import GemeindeProfilMenu from "./GemeindeProfilMenu";

const GemeindeProfil = () => {

    const dispatch = useDispatch();
    const gemeinde = useSelector((state: RootState) => state.gemeinde?.data) as Gemeinde;

    function gemeindeLogoChanged(logo: string) {
        GemeindeService.updateLogo(logo).then(() => {
            GemeindeService.read(gemeinde?.id as string).then(gemeinde => {
                dispatch(gemeindeActions.setGemeinde(gemeinde));
            })
        })
    }

    return (
        <>
            <AccountLogo image={gemeinde.logo as string} imageChanged={gemeindeLogoChanged}/>
            <GemeindeProfilMenu/>
        </>
    )

}

export default GemeindeProfil;