import './Spinner.scss';

export interface SpinnerProps {
    small?: boolean;
    marginTop?: number;
}

const Spinner = (props: SpinnerProps) => {

    let growSpinnerCls = 'spinner-grow text-primary';
    if (props.small) {
        growSpinnerCls += ' spinner-grow-sm';
    }

    const propsStyle: any = {};
    if (props.marginTop) {
        propsStyle.marginTop = props.marginTop+'px';
    }

    return <div style={propsStyle} className='me-spinner-container'>
        <div className={growSpinnerCls}></div>
    </div>
}

export default Spinner;