import './ProfileFormGroup.scss';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";


export interface ProfileFormGroupProps {
    label: string;
    icon: string;
}

const ProfileFormGroup = (props: ProfileFormGroupProps) => {

    return (
        <div className={'me-flex-row me-margin-lg-top me-margin-md-bottom me-align-item-start'}>
            {props.icon && <FontAwesomeIcon className={'me-profile-group-icon'} icon={props.icon as IconProp}/>}
            <div className={'me-profile-group-text'}>{props.label}</div>
        </div>

    )
}


export default ProfileFormGroup;