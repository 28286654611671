import React, {useState} from "react";
import ProfilMenuButton from "../profil-menu-button/ProfilMenuButton";
import EditGeneralSettingsForm from "./profil/Profil";
import EditWeblinks from "./weblinks/EditWeblinks";
import EditCompanyAbout from "./about/EditCompanyAbout";
import OpeningHoursSettingsForm from "./openHours/OpeningHours";
import SpecialOpeningHourPage from "./specialOpenHours/SpecialOpeningHours";
import EditCompanyTeam from "./team/EditCompanyTeam";
import CompanyAddressEdit from "./company-address/CompanyAddressEdit";


const CompanyProfilMenu = () => {


    const [modalGeneralSettingsOpen, setModalGeneralSettingsOpen] = useState(false);
    const [showAddressEdit, setShowAddressEdit] = useState(false);
    const [modalWeblinksOpen, setModalWeblinksOpen] = useState(false);
    const [modalAboutOpen, setModalAboutOpen] = useState(false);
    const [modalOpeningHoursSettingsOpen, setModalOpeningHoursSettingsOpen] = useState(false);
    const [modalSpecialOpeningHoursSettingsOpen, setModalSpecialOpeningHoursSettingsOpen] = useState(false);
    const [modalTeamOpen, setModalTeamOpen] = useState(false);

    return (
        <>
            <div className='me-flex-column me-scrollable'>
                <ProfilMenuButton
                    label={'Profil'} icon={'address-card'}
                    onClick={() => setModalGeneralSettingsOpen(true)}/>
                <ProfilMenuButton
                    label={'Adresse'} icon={'house-flag'}
                    onClick={() => setShowAddressEdit(true)}/>
                <ProfilMenuButton
                    label={'Weblinks'} icon={'globe'}
                    onClick={() => setModalWeblinksOpen(true)}/>
                <ProfilMenuButton
                    label={'Öffnungszeiten'} icon={'clock'}
                    onClick={() => setModalOpeningHoursSettingsOpen(true)}/>
                <ProfilMenuButton
                    label={'Sonderöffnungszeiten'} submenu={true}
                    onClick={() => setModalSpecialOpeningHoursSettingsOpen(true)}/>
                <ProfilMenuButton
                    label={'Über uns'} icon={'images'}
                    onClick={() => setModalAboutOpen(true)}/>
                <ProfilMenuButton
                    label={'Team'} icon={'user-group'}
                    onClick={() => setModalTeamOpen(true)}/>
            </div>
            {modalGeneralSettingsOpen &&
                <EditGeneralSettingsForm
                    showModal={modalGeneralSettingsOpen}
                    onSave={() => {
                        setModalGeneralSettingsOpen(false);
                    }}
                    onCancel={() => setModalGeneralSettingsOpen(false)}/>
            }
            {showAddressEdit && <CompanyAddressEdit
                onSaved={() => setShowAddressEdit(false)}
                onCancel={() => setShowAddressEdit(false)}/>
            }
            {modalWeblinksOpen &&
                <EditWeblinks
                    onSave={() => setModalWeblinksOpen(false)}
                    onCancel={() => setModalWeblinksOpen(false)}/>
            }
            {modalAboutOpen &&
                <EditCompanyAbout
                    showModal={modalAboutOpen}
                    onSave={() => setModalAboutOpen(false)}
                    onCancel={() => setModalAboutOpen(false)}/>
            }
            {modalOpeningHoursSettingsOpen &&
                <OpeningHoursSettingsForm
                    showModal={modalOpeningHoursSettingsOpen}
                    onSave={() => {
                        setModalOpeningHoursSettingsOpen(false);
                    }}
                    onCancel={() => setModalOpeningHoursSettingsOpen(false)}/>
            }
            {modalSpecialOpeningHoursSettingsOpen &&
                <SpecialOpeningHourPage
                    showModal={modalSpecialOpeningHoursSettingsOpen}
                    onSave={() => {
                        setModalSpecialOpeningHoursSettingsOpen(false);
                    }}
                    onCancel={() => setModalSpecialOpeningHoursSettingsOpen(false)}/>
            }
            {modalTeamOpen &&
                <EditCompanyTeam
                    showModal={modalTeamOpen}
                    onSave={() => setModalTeamOpen(false)}
                    onCancel={() => setModalTeamOpen(false)}/>
            }
        </>
    )

}

export default CompanyProfilMenu;