import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { AssertOpenPayments, Payment } from "../data/payment/Payment";

export interface PaymentState {
    loading: boolean;
    payments: AssertOpenPayments;
}

const initialState: PaymentState = {
    loading: false,
    payments: {} as AssertOpenPayments
}


const paymentsSlice = createSlice({
    name: 'payments',
    initialState: initialState,
    reducers: {
        loading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        replaceAll(state, action: PayloadAction<AssertOpenPayments>) {
            state.payments = action.payload;
        },
    }
})

export const paymentsActions = paymentsSlice.actions;
export default paymentsSlice.reducer;