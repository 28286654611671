import './CreateEventForm.scss'

import React, {useEffect, useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import Spinner from "../../../ui/spinner/Spinner";
import {EventService} from "../../../data/event/Event.service";
import {AddEvent, emptyEventLayout, Event, EventAction, EventActionType} from "../../../data/event/Event";
import InvitationList from "../invitation-list/InvitationList";
import EventLayoutDesigner from "../layout/EventLayoutDesigner";
import VerticalMenu, {VerticalMenuItem} from "../../../ui/menu/VerticalMenu";
import {SurveyState} from "../../../data/survey/Survey";
import VisibilityToggler from "../../base/visibilty/visibility-toggler";
import {Visibility} from "../../../data/base/Visibility";
import {DateTime} from "../../../data/base/DateTime";
import DatesTimesEditor from "../../../ui/date/datetime/DatesTimesEditor";
import CreateEventActionForm from "./CreateEventActionForm";

export interface CreateEventFormProps {
    showModal: boolean;
    initialEvent?: Event;
    onSave: () => void;
    onCancel: () => void;
}

const CreateEventForm = (props: CreateEventFormProps) => {

    const [selectedMenu, setSelectedMenu] = useState('DATEN');
    const [title, setTitle] = useState('');
    const [subtitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [visibility, setVisibility] = useState<Visibility>(Visibility.PUBLIC);
    const [datesTimes, setDatesTimes] = useState<DateTime[]>([]);
    const [invitationClientIds, setInvitationClientIds] = useState<string[]>([])
    const [layout, setLayout] = useState(emptyEventLayout);
    const [action, setAction] = useState<EventAction | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [validated, setValidated] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const menuItems: VerticalMenuItem[] = [
        {code: 'DATEN', label: 'Daten', disabled: false},
        {code: 'PARTICIPANTS', label: 'Einladungsliste', disabled: visibility !== Visibility.LIST},
        {code: 'ACTIONS', label: 'Aktion', disabled: false},
        {code: 'LAYOUT', label: 'Design', disabled: false}
    ]

    useEffect(() => {
        if (props.showModal) {
            if (props.initialEvent) {
                mapInitial(props.initialEvent);
            }
        }
    }, [props.showModal, props.initialEvent])

    function mapInitial(initial: Event) {
        setTitle(initial.title);
        setSubTitle(initial.subtitle);
        setDescription(initial.description);
        setVisibility(initial.visibility as Visibility);
        setAction(initial.action);
        setLayout(initial.layout);
        setInvitationClientIds([...initial.invitationList]);
        setDatesTimes([...initial.dates]);
    }

    function onTitleChanged(event: any) {
        setErrorMessage('');
        setTitle(event.target.value);
    }

    function onSubtitleChanged(event: any) {
        setErrorMessage('');
        setSubTitle(event.target.value);
    }

    function onDescriptionChanged(event: any) {
        setErrorMessage('');
        setDescription(event.target.value);
    }

    function onVisibilityChanged(visibility: Visibility) {
        setErrorMessage('');
        if (visibility !== Visibility.LIST) {
            setInvitationClientIds([]);
        }
        setVisibility(visibility);
    }

    function onDateTimesChanged(datesTimes: DateTime[]) {
        setErrorMessage('');
        setDatesTimes(datesTimes);
    }

    function onInvitationClientIdsChanged(clientIds: string[]) {
        setInvitationClientIds(clientIds);
    }

    function onActionChanged(action: EventAction) {
        setErrorMessage('');
        setAction(action)
    }

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function saved() {
        resetForm();
        props.onSave();
    }

    function error(e: any) {
        setErrorMessage(e.response ? e.response.data : e.message)
        setSaving(false);
    }

    function resetForm() {
        setSelectedMenu('DATEN');
        setErrorMessage('');
        setErrorMessage('');
        setSaving(() => false);
        setValidated(() => false);
        setTitle(() => '');
        setSubTitle(() => '');
        setDescription(() => '');
        setInvitationClientIds(() => []);
        setVisibility(() => Visibility.PUBLIC);
        setDatesTimes([]);
        setAction(null);
        setLayout(emptyEventLayout);
    }

    function save() {
        setErrorMessage('');
        setValidated(true);
        if (!isValid()) {
            return;
        }
        setSaving(true);
        if (props.initialEvent) {
            EventService.updateEvent(props.initialEvent.eventId, createUpdateEvent())
                .then(saved)
                .catch(error);
        } else {
            EventService.addEvent(createAddEvent())
                .then(saved)
                .catch(error);
        }
    }

    function nextMenu(): void {
        if (selectedMenu === 'DATEN') {
            setValidated(true);
            if (isValidDataForm()) {
                visibility === Visibility.LIST ? setSelectedMenu('PARTICIPANTS') : setSelectedMenu('ACTIONS');
            }
        } else if (selectedMenu === 'PARTICIPANTS') {
            setSelectedMenu('ACTIONS');
        } else if (selectedMenu === 'ACTIONS') {
            if (isValidActionForm()) {
                setSelectedMenu('LAYOUT');
            }
        }
    }

    function createUpdateEvent(): Event {
        return {
            eventId: props.initialEvent?.eventId as string,
            state: props.initialEvent?.state as SurveyState,
            companyId: props.initialEvent?.companyId as string,
            title: title,
            subtitle: subtitle,
            description: description,
            dates: datesTimes,
            visibility: visibility,
            layout: layout,
            action: action as EventAction,
            invitationList: invitationClientIds,
            participants: [],
            comments: [],
            created: props.initialEvent?.created as string,
        }
    }

    function createAddEvent(): AddEvent {
        return {
            title: title,
            subtitle: subtitle,
            description: description,
            dates: datesTimes,
            visibility: visibility,
            invitationList: invitationClientIds,
            layout: layout,
            action: action as EventAction
        }
    }

    function isValid(): boolean {
        return isValidDataForm() && isValidActionForm();
    }

    function isValidDataForm() {
        if (!title) {
            setErrorMessage("Titel erforderlich");
            return false;
        }
        if (datesTimes.length === 0) {
            setErrorMessage("Dauer des Events erforderlich");
            return false;
        }
        return true;
    }

    function isValidActionForm() {
        if (!action || !action.type) {
            setErrorMessage("Aktion erforderlich");
            return false;
        }
        if (action.type === EventActionType.EXTERNAL_LINK) {
            if (!action.externalLink) {
                setErrorMessage("Link erforderlich");
                return false;
            }
        }
        return true;
    }

    function createForm() {
        return (
            <div className='me-flex-column me-flex-grow'>
                <VerticalMenu items={menuItems} onSelect={setSelectedMenu} selectedCode={selectedMenu}>
                    {selectedMenu === 'DATEN' &&
                        <Form id='createEventForm'
                              className='me-create-event-form'
                              noValidate
                              validated={validated}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    placeholder={'Titel*'} required
                                    value={title}
                                    onChange={onTitleChanged}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    placeholder={'Untertitel'}
                                    value={subtitle}
                                    onChange={onSubtitleChanged}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    as="textarea" rows={5}
                                    placeholder={'Beschreibung'}
                                    value={description}
                                    onChange={onDescriptionChanged}/>
                            </Form.Group>
                            <Form.Group className="mt-3 mb-3">
                                <VisibilityToggler
                                    initialValue={visibility}
                                    onChange={onVisibilityChanged}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Eventzeitpunkt</Form.Label>
                                <DatesTimesEditor
                                    minOne={true}
                                    values={datesTimes}
                                    onChange={onDateTimesChanged}
                                />
                            </Form.Group>

                        </Form>
                    }
                    {selectedMenu === 'PARTICIPANTS' &&
                        <div className='me-create-event-invitation-list'>
                            <InvitationList
                                initialInvitationClientIds={invitationClientIds}
                                invitationClientIdsChanged={onInvitationClientIdsChanged}
                            />
                        </div>
                    }
                    {selectedMenu === 'ACTIONS' &&
                        <CreateEventActionForm
                            formValidated={validated}
                            value={action}
                            onChange={onActionChanged}
                        />
                    }
                    {selectedMenu === 'LAYOUT' &&
                        <EventLayoutDesigner
                            title={title}
                            subtitle={subtitle}
                            defaultLayout={layout}
                            dateTimes={datesTimes}
                            layoutChanged={setLayout}
                        />
                    }
                </VerticalMenu>
                <Alert className={`me-save-event-alert ${errorMessage !== '' ? 'show-error' : ''}`}
                       variant='danger'>{errorMessage}</Alert>
            </div>
        )
    }

    return (
        <Modal show={props.showModal}
               className={'me-create-form-modal'}
               backdrop="static"
               onHide={cancel}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Event planen</Modal.Title>
            </Modal.Header>
            <Modal.Body>{createForm()}</Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && selectedMenu === 'LAYOUT' &&
                    <Button variant="primary" onClick={save}> Speichern </Button>}
                {!isSaving && selectedMenu !== 'LAYOUT' &&
                    <Button variant="primary" onClick={nextMenu}> Weiter </Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default CreateEventForm;