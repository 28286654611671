import './Help.scss'
import React, {useState} from "react";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Button, Col, Form, Modal} from "react-bootstrap";
import {CompanyService} from "../../data/company/Company.service";
import {SendFeedback} from "../../data/company/Feedback";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import VerticalMenu, {VerticalMenuItem} from "../../ui/menu/VerticalMenu";


const Help = () => {

    const company = useSelector((state: RootState) => state.company?.data);
    const [openHelpPage, setOpenHelpPage] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState('FOLLOWER');
    const [feedbackText, setFeedbackText] = useState('');
    const [emailSuccessfullSent, setEmailSuccessfullSent] = useState(false);
    const [error, setError] = useState(false);

    const menuItems: VerticalMenuItem[] = [
        {code: 'FOLLOWER', label: 'Follower', disabled: false},
        {code: 'POSTS', label: 'Posts', disabled: false},
        {code: 'EVENTS', label: 'Events', disabled: false},
        {code: 'SURVEYS', label: 'Umfragen', disabled: false},
        {code: 'FEEDBACK', label: 'Gib uns Feedback!', disabled: false}
    ]

    function createFollowerPage() {
        return <Col>
            <h2>Follower</h2>
            <div>In dieser Kachel siehst und verwaltest du alle Personen, die deinem Unternehmensprofil in der Me App
                folgen.<br/><br/>
                <b>Follower</b> sind alle Personen, die dir folgen, im Überblick. <br/><br/>
                <b>Kontakte</b> sind Personen, die du kennst und als Kontakt gekennzeichnet hast.<br/><br/>
                Du kannst Personen, die du kennst, mit dem Check-Button als Kontakt hinzufügen. Somit hast du die
                Möglichkeit, Events nur an deine Kontakte zu senden.
            </div>
        </Col>
    }

    function createNotificationsPage() {
        return <Col>
            <h2>Posts</h2>
            <div>Hast du Neuigkeiten? <br/>Ein tolles Sonderangebot?<br/> Oder willst du deinen Followern sonst etwas
                mitteilen?<br/><br/>
                Dann nutze dafür die Post-Funktion. Mit ihr kannst du ganz einfach Angebote erfassen und deinen
                Followern in der Me App präsentieren.
            </div>
        </Col>
    }

    function createEventsPage() {
        return <Col>
            <h2>Event</h2>
            <div>Gibt es etwas zu feiern? <br/>Oder organisierst du einen Anlass? <br/><br/>Dann nutze dafür die
                Event-Funktion.
                Mit ihr kannst du ganz einfach Events versenden. Deine Follower werden per Push-Nachricht
                in der Me App über die Einladung informiert. Sie können ihre Teilnahme anmelden oder via Chat Fragen mit
                dir
                klären.
            </div>
        </Col>
    }

    function createSurveysPage() {
        return <Col>
            <h2>Umfagen</h2>
            <p>Du möchtest ein neues Produkt lancieren? <br/>
                Du hast eine Idee und hättest gerne Kundenfeedback? <br/>
                Du organisiertst einen Anlass und benötigst zur Planung noch Informationen? <br/>
                Oder du willst sonst eine Frage in die Runde werfen?<br/> <br/>
                Das geht jetzt ganz einfach mit der Unfragefunktion.
                Hier kannst du Umfragen erstellen und mit deinen Followern teilen.
                Dazu erfasst du Fragen und definierst deine individuellen Antwortmöglichkeiten, die deine Follower
                anklicken können. Den Umfragezeitraum kannst du selber festlegen.
                Im Anschluss kannst du dir in Ruhe die Auswertungen anschauen.
            </p>
        </Col>
    }

    function onFeedbackChanged(event: any) {
        setFeedbackText(event.target.value)
    }

    function sendFeedback() {
        if (feedbackText.length <= 0) {
            return;
        }
        setError(false);
        setEmailSuccessfullSent(false);

        let companyFeedback: SendFeedback = {
            companyName: company?.company as any,
            companyEmail: company?.email as any,
            feedback: feedbackText
        };
        CompanyService.sendFeedbackToOnra(companyFeedback)
            .then(() => {
                setEmailSuccessfullSent(true);
                setError(false);
                setTimeout(() => {
                    setEmailSuccessfullSent(false);
                }, 3000);
            })
            .catch(() => {
                setEmailSuccessfullSent(false);
                setError(true);
            });


        setFeedbackText('');

    }

    function createFeedbackPage() {
        return <Col className={'me-help-detail'}>
            <Form>
                <Form.Label className={'mb-3'}>Wir freuen uns über dein Feedback oder deine Frage.</Form.Label>
                <Form.Group className="mb-3">
                    <Form.Control
                        as="textarea" rows={8}
                        value={feedbackText}
                        placeholder={'Feedback'}
                        onChange={onFeedbackChanged}/>
                </Form.Group>
            </Form>
            <Button className={'mb-3'} onClick={sendFeedback}>senden</Button>
            <img className={'me-help-onra-logo'} src={require('../../images/onra-logo.png')} alt={''}></img>
            {error && <Alert variant={"danger"}>{'Fehler beim senden. Bitte schreibe an info@onra.ch'}</Alert>}
            {emailSuccessfullSent && <Alert variant={"info"}>{'Nachricht erfolgreich gesendet!'}</Alert>}
        </Col>
    }

    return (
        <>
            <div className={'me-help-container'}>
                <FontAwesomeIcon
                    id={'me-header-question-icon'}
                    className={'me-header-question-btn'}
                    color={'black'}
                    icon={faQuestionCircle} size={'2x'}
                    onClick={() => setOpenHelpPage(!openHelpPage)}/>
            </div>

            {openHelpPage &&
                <Modal show={openHelpPage}
                       size={"xl"}
                       backdrop="static"
                       onHide={() => setOpenHelpPage(false)}
                       centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Hilfe </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='me-help-content-container'>
                            <VerticalMenu items={menuItems} onSelect={setSelectedMenu}>
                                {selectedMenu === 'FOLLOWER' && createFollowerPage()}
                                {selectedMenu === 'POSTS' && createNotificationsPage()}
                                {selectedMenu === 'EVENTS' && createEventsPage()}
                                {selectedMenu === 'SURVEYS' && createSurveysPage()}
                                {selectedMenu === 'FEEDBACK' && createFeedbackPage()}
                            </VerticalMenu>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setOpenHelpPage(false)}>schliessen</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );

};

export default Help;