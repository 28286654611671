import './ExternalLink.scss';
import React, {ChangeEvent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

interface ExternalLinkProps {
    value: string;
    onChanged: (code: string) => void;
}


const ExternalLink = (props: ExternalLinkProps) => {

    function onInputChanged(event: ChangeEvent<HTMLInputElement>) {
        props.onChanged(event.target.value);
    }

    function validateUrl() {
        window.open(props.value);
    }

    return (

        <div className={'me-external-link'}>
            <input value={props.value} type="text" onChange={(event) => onInputChanged(event)}></input>
            <Button
                variant={"outline-secondary"}
                className='me-open-modal-button'
                onClick={() => validateUrl()}>
                <FontAwesomeIcon icon={faGlobe}></FontAwesomeIcon>
                <div className={'me-open-modal-button-label'}>Test</div>
            </Button>
        </div>
    )


}

export default ExternalLink;