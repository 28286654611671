import Form from "react-bootstrap/Form";
import {Button, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {AddressLocation} from "../../data/address/AddressLocation";

export interface GemeindeLocationFormRowProps {
    location: AddressLocation;
    onDelete: () => void;
    onChanged: (location: AddressLocation) => void;
}

const AddressLocationsFormRow = (props: GemeindeLocationFormRowProps) => {

    const [name, setName] = useState(props.location.name);
    const [zip, setZip] = useState(props.location.zip);
    const [latitude, setLatitude] = useState(props.location.coordinate.latitude);
    const [longitude, setLongitude] = useState(props.location.coordinate.longitude);

    useEffect(() => {
        props.onChanged(mapFromState());
    }, [name, zip, latitude, longitude]);

    function mapFromState(): AddressLocation {
        return {
            id: props.location.id,
            name: name,
            zip: zip,
            coordinate: {
                latitude: latitude ? latitude : '',
                longitude: longitude ? longitude : ''
            }
        }
    }

    return (
        <>
            <Form.Control
                id='name'
                placeholder={'Name*'}
                onChange={(event) => setName(event.target.value)}
                value={name}
                required>
            </Form.Control>
            <Form.Control
                id='zip'
                className='mt-3'
                type={'number'}
                placeholder={'Zip*'}
                onChange={(event) => setZip(event.target.value)}
                value={zip}
                required>
            </Form.Control>
            <Row>
                <Form.Group as={Col} className="mt-3 mb-3">
                    <Form.Control
                        id='latitude'
                        placeholder={'Latitude*'}
                        onChange={(event) => setLatitude(event.target.value)}
                        value={latitude}
                        required>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} className="mt-3 mb-3">
                    <Form.Control
                        id='longitude'
                        placeholder={'Longitude*'}
                        onChange={(event) => setLongitude(event.target.value)}
                        value={longitude}
                        required>
                    </Form.Control>
                </Form.Group>
            </Row>
            <Button variant="secondary" onClick={props.onDelete}> Löschen </Button>
        </>
    )

}

export default AddressLocationsFormRow;