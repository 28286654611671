import './CompanyAddressEdit.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import React, {useEffect, useState} from "react";
import {Address} from "../../../../data/address/Address";
import {areArraysEqual} from "../../../../data/base/ObjectUtil";
import {Alert, Button, Modal} from "react-bootstrap";
import ProfileFormGroup from "../../profil-form-group/ProfileFormGroup";
import Spinner from "../../../../ui/spinner/Spinner";
import AddressForm from "../../../address/AddressForm";
import AddressLocationsForm from '../../../address/AddressLocationsForm';
import {CompanyService} from "../../../../data/company/Company.service";
import {companyActions} from "../../../../store/company";
import {AddressLocation} from "../../../../data/address/AddressLocation";
import Company from "../../../../data/company/Company";

export interface CompanyAddressEditProps {
    onSaved: () => void;
    onCancel: () => void;
}

const CompanyAddressEdit = (props: CompanyAddressEditProps) => {

    const dispatch = useDispatch();
    const company = useSelector((state: RootState) => state.company.data) as Company;
    const companyId = company ? company.id : '';

    const [isSaving, setSaving] = useState(false);
    const [error, setError] = useState('');
    const [changes, setChanges] = useState(false);
    const [address, setAddress] = useState<Address | null>();
    const [locations, setLocations] = useState<AddressLocation[]>(company.locations);

    useEffect(() => {
        setChanges(hasChanges);
    }, [address, locations]);

    function cancel() {
        resetForm();
        props.onCancel();
    }

    function resetForm() {
        setError('');
    }

    function save() {
        if (!validData()) {
            return;
        }
        setError('');
        setSaving(true);
        const updateAddress = {
            address: address as Address,
            locations: locations as AddressLocation[]
        }
        CompanyService.updateAddress(companyId, updateAddress)
            .then(() => {
                dispatch(companyActions.loading(true));
                CompanyService.read(companyId).then(company => {
                    dispatch(companyActions.loading(false));
                    dispatch(companyActions.setCompany(company))
                    props.onSaved();
                });
            })
            .catch((error) => {
                    setSaving(false);
                    setError(error.message);
                }
            );
    }

    function hasChanges(): boolean {
        const addressChanged =
            company!.address?.street !== address?.street ||
            company!.address?.streetNumber !== address?.streetNumber ||
            company!.address?.zip !== address?.zip ||
            company!.address?.city !== address?.city ||
            company!.address?.coordinate?.latitude !== address?.coordinate?.latitude ||
            company!.address?.coordinate?.longitude !== address?.coordinate?.longitude;
        const locationsEquals = areArraysEqual(company!.locations, locations as AddressLocation[]);
        return addressChanged || !locationsEquals;
    }

    function validData(): boolean {
        if (!address) {
            setError('Addresse ist erforderlich');
            return false;
        }
        if (!address.street) {
            setError('Strasse ist erforderlich');
            return false;
        }
        if (!address.zip) {
            setError('Plz ist erforderlich');
            return false;
        }
        if (!address.city) {
            setError('Ort ist erforderlich');
            return false;
        }
        if (!address.state) {
            setError('Kanton ist erforderlich');
            return false;
        }
        if (!address.coordinate?.latitude) {
            setError('Latitude ist erforderlich');
            return false;
        }
        if (!address.coordinate?.longitude) {
            setError('Longitude ist erforderlich');
            return false;
        }
        if (isNaN(parseFloat(address.coordinate.latitude))) {
            setError('Latitude hat falsches Format');
            return false;
        }
        if (isNaN(parseFloat(address.coordinate.longitude))) {
            setError('Longitude hat falsches Format');
            return false;
        }
        const invalidLocs = locations!.filter(loc => !loc.name || !loc.zip || !loc.coordinate?.latitude || !loc.coordinate?.longitude);
        if (invalidLocs.length > 0) {
            setError('Kartenpunkte nicht vollständig erfasst');
            return false;
        }

        return true;
    }

    return <>
        <Modal show={true}
               backdrop="static"
               onHide={cancel}
               size={'lg'}
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Adresse</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'me-margin-md'}>
                    <ProfileFormGroup label={'Adresse'} icon={'house-flag'}/>
                    <AddressForm
                        address={company!.address}
                        onChanged={setAddress}/>
                    <ProfileFormGroup label={'Weitere Standorte (optional)'} icon={'location'}/>
                    <AddressLocationsForm
                        locations={locations}
                        onChanged={setLocations}
                    />
                </div>
                {error &&
                    <Alert className='settings-error' variant='danger'>{error}</Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner></Spinner>}
                {!isSaving && <Button variant="secondary" onClick={cancel}> Abbrechen </Button>}
                {!isSaving && <Button disabled={!changes} variant="primary" onClick={save}> Speichern </Button>}
            </Modal.Footer>
        </Modal>
    </>

}

export default CompanyAddressEdit;