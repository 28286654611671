import './IconButton.scss';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";

interface IconButtonProps {
    iconName: any;
    disabled?: boolean;
    invisible?: boolean;
    onClick?: (e: any) => void;
    className?: string;
    tooltip?: string;
    buttonLabel?: string;
    minWithIPad?: boolean;
    marginLeft?: number;
    marginRight?: number;
    marginBottom?: number;
    toggled?: boolean;
}


const IconButton = (props: IconButtonProps) => {

    let className = 'me-icon-button ';
    if (props.className) {
        className += props.className;
    }
    if (props.disabled) {
        className += ' disabled';
    }

    let propsStyle: any = {};
    if (props.marginLeft) {
        propsStyle.marginLeft = props.marginLeft;
    }
    if (props.marginRight) {
        propsStyle.marginRight = props.marginRight;
    }
    if (props.marginBottom) {
        propsStyle.marginBottom = props.marginBottom;
    }
    if (props.invisible) {
        propsStyle.opacity = 0;
    }

    function onIconClicked(e: any) {
        if (props.onClick && !props.disabled) {
            props.onClick(e);
        }
    }

    return (
        <Button
            onClick={onIconClicked}
            style={propsStyle}
            variant={"outline-secondary"}
            className={className}>
            <FontAwesomeIcon
                title={props.tooltip}
                icon={props.iconName}/>
            {props.buttonLabel !== undefined &&
                <div
                    className={`me-icon-button-label ${props.minWithIPad ? 'min-width' : ''}`}>{props.buttonLabel}</div>
            }
        </Button>

    )

};


export default IconButton;