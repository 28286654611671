export enum SwissCanton {
    AG = "AG",
    AR = "AR",
    AI = "AI",
    BL = "BL",
    BS = "BS",
    BE = "BE",
    FR = "FR",
    GE = "GE",
    GL = "GL",
    GR = "GR",
    JU = "JU",
    LU = "LU",
    NE = "NE",
    NW = "NW",
    OW = "OW",
    SG = "SG",
    SH = "SH",
    SZ = "SZ",
    SO = "SO",
    TG = "TG",
    TI = "TI",
    UR = "UR",
    VD = "VD",
    VS = "VS",
    ZG = "ZG",
    ZH = "ZH"
}