import {isLocalhost} from "../data/host.env";

export function defaultEmail() {
    if (isLocalhost()) {
        return 'fritz@bar.ch';
    }
    return '';
}

export function defaultPasswort() {
    if (isLocalhost()) {
        return 'fritzbar';
    }
    return '';
}