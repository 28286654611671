import './EditCompanyAboutItemPopup.scss';
import {Alert, Button, Form} from "react-bootstrap";
import ImageSelector from "../../../../ui/image-selector/ImageSelector";
import React, {useEffect, useState} from "react";
import {CompanyAboutItem} from "../../../../data/company/about/CompanyAbout";

export interface EditCompanyAboutItemProps {
    defaultItem?: CompanyAboutItem | null;
    close: () => void;
    addItem: (item: CompanyAboutItem) => void;
}

const EditCompanyAboutItemPopup = (props: EditCompanyAboutItemProps) => {

    const [error, setError] = useState('');
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        if (props.defaultItem) {
            setImage(props.defaultItem.image);
            setTitle(props.defaultItem.title);
            setDescription(props.defaultItem.description);
        }
    }, [])


    function onImageChanged(image: string) {
        setError('');
        setImage(image);
    }

    function onTitleChanged(event: any) {
        setError('');
        setTitle(event.target.value)
    }

    function onDescriptionChanged(event: any) {
        setError('');
        setDescription(event.target.value)
    }

    function save() {
        if (!image) {
            setError("Ein Bild ist erforderlich.");
            return;
        }
        const id = props.defaultItem ? props.defaultItem.id : new Date().getTime().toString(36);
        props.addItem({
            id: id,
            image: image,
            title: title,
            description: description
        })
    }

    return (
        <>
            <div className={'me-company-edit-about-item-backdrop'}/>
            <div className={'me-company-edit-about-item-popup'}>
                <Form>
                    <Form.Group className="mb-3">
                        <ImageSelector
                            defaultImage={image}
                            imageMaxSizeMB={0.4}
                            imageSelected={onImageChanged}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            placeholder={'Titel'}
                            value={title}
                            maxLength={50}
                            onChange={onTitleChanged}
                            required>
                        </Form.Control>
                        <div className={'me-max-character-indicator'}>{title?.length}/50</div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            as="textarea" rows={4}
                            placeholder={'Beschreibung'}
                            value={description}
                            maxLength={500}
                            onChange={onDescriptionChanged}/>
                        <div className={'me-max-character-indicator'}>{description?.length}/500</div>
                    </Form.Group>
                </Form>
                {error && <Alert className={`me-add-company-item-alert`} variant='danger'>{error}</Alert>}
                <div className={'me-company-edit-about-item-popup-footer'}>
                    <Button variant="secondary" onClick={props.close}> Abbrechen </Button>
                    <Button variant="primary" onClick={save}> Übernehmen </Button>
                </div>
            </div>
        </>
    )

}

export default EditCompanyAboutItemPopup;