import './ChatTile.scss';


import {Card} from "react-bootstrap";
import React from "react";
import IconButton from "../../../ui/icon-button/IconButton";
import {DateUtil} from "../../../data/DateUtil";
import {Chat} from "../../../data/chat/Chat";
import {ChatService} from "../../../data/chat/ChatService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PartnerType} from "../../../data/base/PartnerType";
import {NotificationType} from "../../../data/base/NotificationType";


export interface ChatTileProps {
    chat: Chat;
    onDeleted: () => void;
    onEmailNotification: (active: boolean) => void;
    onTileClicked: (chatId: string) => void;
}

const ChatTile = (props: ChatTileProps) => {

    const chat = props.chat;
    const title = chat.topic ? chat.topic : chat.ownerName;

    const isEmailNotificationActive = determineEmailNotificationActive();

    function determineEmailNotificationActive() {
        if (chat.ownerPartnerType === PartnerType.COMPANY && chat.ownerNotificationType === NotificationType.EMAIL) {
            return true;
        }
        return chat.directPartnerType === PartnerType.COMPANY && chat.directPartnerNotificationType === NotificationType.EMAIL;
    }

    function deleteChat(e: any) {
        e.stopPropagation();
        ChatService.delete(chat.chatId)
            .then(() => {
                props.onDeleted();
            })
    }

    function onEmailNotification(e: any, active: boolean) {
        e.stopPropagation();
        props.onEmailNotification(active);
    }

    return (
        <Card onClick={() => props.onTileClicked(chat.chatId)} className={'chat-card'}>
            <Card.Body className={'me-flex-column'}>
                <Card.Title className={'me-chat-tile-title'}>
                    <div className={'title'}>{title}</div>
                    {chat.type === 'CHANNEL' &&
                        <div className={'me-chat-tile-channel-info'}>
                            <FontAwesomeIcon title={'Direktnachricht'} className={'me-chat-tile-channel-info-logo'}
                                             icon={'at'}/>
                            <div className={'me-chat-tile-direktmessage-text'}>Channel</div>
                        </div>
                    }
                    {chat.type === 'DIRECT' &&
                        <div className={'me-chat-tile-channel-info'}>
                            <FontAwesomeIcon title={'Direktnachricht'} className={'me-chat-tile-channel-info-logo'}
                                             icon={'user'}/>
                        </div>
                    }
                </Card.Title>
                <Card.Text className={'me-chat-tile-message'}>
                    <div>{chat.lastComment.message}</div>
                    <div className={'me-chat-tile-comment-info'}>
                        <div className={'me-chat-tile-comment-user-name'}>{chat.lastComment.userName}</div>
                        <div className={'me-chat-tile-comment-created'}>
                            {DateUtil.displayShort(chat.lastComment.created)}
                        </div>
                    </div>
                </Card.Text>
                <div className={'me-chat-infos'}>
                    {isEmailNotificationActive && <div>Email Benachrichtigung Ein</div>}
                    {!isEmailNotificationActive && <div>Email Benachrichtigung Aus</div>}
                </div>
                <div className={'me-chat-card-acions'}>
                    <div className={'me-chat-card-acions-left'}>
                        {isEmailNotificationActive &&
                            <IconButton className={'toggled'} iconName={'envelope'}
                                        onClick={(e) => onEmailNotification(e, false)}/>}
                        {!isEmailNotificationActive &&
                            <IconButton className={'not-toggled'} iconName={'envelope'}
                                        onClick={(e) => onEmailNotification(e, true)}/>}
                    </div>
                    <div className={'me-chat-card-acions-right'}>
                        <IconButton className={'red-hover'} iconName={'trash-can'} onClick={deleteChat}/>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )

}

export default ChatTile;