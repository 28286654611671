import axios from "axios";
import {host} from "../host.env";

export interface PasswordReset {
    email: string;
    code: string;
    password: string;
}

export class PasswordService {

    static EMAIL_SENT = "EMAIL_SENT";
    static PASSWORD_SUCCESSFUL_CHANGED = "PASSWORD_SUCCESSFUL_CHANGED";
    static REQUEST_FAILED_EMAIL_NOT_IN_USE = "REQUEST_FAILED_EMAIL_NOT_IN_USE";
    static REQUEST_FAILED_EMAIL_ALREADY_IN_USE = "REQUEST_FAILED_EMAIL_ALREADY_IN_USE";
    static REQUEST_FAILED_NO_CODE_FOUND = "REQUEST_FAILED_NO_CODE_FOUND";
    static REQUEST_FAILED_CODE_NOT_EQUAL = "REQUEST_FAILED_CODE_NOT_EQUAL";


    public static async requestReset(email: string) {
        return await axios.post(host() + `auth/company/password/reset/request`, {email: email}, {withCredentials: true})
            .then(response => {
                if (response.data === PasswordService.EMAIL_SENT) {
                    return PasswordService.EMAIL_SENT;
                }
                return response.data
            }).catch(e => console.log("Error create account", e));
    }

    public static async doReset(passwordReset: PasswordReset) {
        return await axios.post(host() + `auth/company/password/reset`, passwordReset, {withCredentials: true})
            .then(response => {
                return response.data;
            }).catch(e => console.log("Error create account", e));
    }

}