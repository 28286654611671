import {DateTime} from "./base/DateTime";

export class DateUtil {


    static dateWithoutTime(date: string): Date {
        return new Date(date);
    }

    static dateWithTime(date: string, time: string): Date {
        let dateString = date;
        if (time) {
            dateString += 'T' + time;
        }
        return new Date(dateString);
    }

    static dateWithTimeAsString(date: string, time: string): string {
        if (!date) {
            return '';
        }
        if (time) {
            return date + 'T' + time;
        }
        return date;
    }

    static displayShort(dateString: string) {
        if (!dateString) {
            return '';
        }
        try {
            const date = new Date(dateString);
            const dateFormatter = Intl.DateTimeFormat('de-CH', {dateStyle: 'short', timeStyle: 'short'});
            return dateFormatter.format(date);
        } catch (e) {
            return '';
        }

    }

    static displayDateShortNoTime(date: Date) {
        if (!date) {
            return '';
        }
        const dateFormatter = Intl.DateTimeFormat('de-CH', {dateStyle: 'short'});
        return dateFormatter.format(date);
    }

    static displayShortNoTime(dateString: string) {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        const dateFormatter = Intl.DateTimeFormat('de-CH', {dateStyle: 'short'});
        return dateFormatter.format(date);
    }

    static displayLongNoTime(dateString: string) {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        const dateFormatter = Intl.DateTimeFormat('de-CH', {dateStyle: 'long'});
        return dateFormatter.format(date);
    }

    static isBeforeNow(dateString: string) {
        const date = new Date(dateString);
        return date < new Date();
    }

    static isAfterNow(dateString: string) {
        const date = new Date(dateString);
        return date > new Date();
    }

    static isToday(dateString: string) {
        const date = new Date(dateString);
        return this.isSameDates(date, new Date());
    }

    static isSameDay(startdateString: string, enddateString: string) {
        const start = new Date(startdateString);
        const end = new Date(enddateString);
        return this.isSameDates(start, end);
    }

    static isSameDates(start: Date, end: Date) {
        return start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth() && start.getDate() === end.getDate();
    }

    static enddateCalculated(startdateString: string, enddateString: string | null): string {
        if (enddateString) {
            return enddateString;
        }
        const startdate = new Date(startdateString);
        return DateUtil.copyWithoutTime(startdate).toDateString();
    }

    static enddateCalculatedWithTime(startdateString: string, enddateString: string | null): string {
        if (enddateString) {
            return enddateString;
        }
        const startdate = new Date(startdateString);
        return DateUtil.copyNextDayMidnight(startdate).toDateString();
    }

    static asFormDateString(dateString: string): string {
        // 2023-05-12 from date string
        if (!dateString) {
            return '';
        }
        try {
            return new Date(dateString).toISOString().slice(0, 10);
        } catch (e) {
            return '';
        }

    }


    static asFormTimeString(dateString: string): string {
        // convert to format like 08:10
        if (!dateString) {
            return '';
        }
        return new Date(dateString).toLocaleTimeString();
    }

    static copyWithoutTime(originDate: Date): Date {
        let copy = new Date();
        copy.setFullYear(originDate.getFullYear());
        copy.setMonth(originDate.getMonth());
        copy.setDate(originDate.getDate());
        copy.setHours(0);
        copy.setMinutes(0);
        copy.setSeconds(0);
        copy.setMilliseconds(0);
        return copy;
    }

    static copyNextDayMidnight(originDate: Date): Date {
        let copy = new Date();
        copy.setFullYear(originDate.getFullYear());
        copy.setMonth(originDate.getMonth());
        copy.setDate(originDate.getDate() + 1);
        copy.setHours(0);
        copy.setMinutes(0);
        copy.setSeconds(0);
        copy.setMilliseconds(0);
        return copy;
    }

    static validateDates(startdateAsString: string, enddateAsString: string): string {
        if (startdateAsString) {
            const startdate = new Date(startdateAsString);
            if (startdate.getTime() < new Date().getTime()) {
                return "Startdatum darf nicht in der Vergangenheit liegen";
            }
            if (enddateAsString) {
                const enddate = new Date(enddateAsString);
                if (startdate.getTime() > enddate.getTime()) {
                    return "Startdatum muss vor dem Endedatum sein";
                }
            }
        }
        return '';
    }

    static displayDateFromDateTime(dateTime: DateTime): string {
        const date = new Date(dateTime.date);
        const dateFormatter = Intl.DateTimeFormat('de-CH', {dateStyle: 'medium'});
        return dateFormatter.format(date);
    }

    static displayTimeFromDateTime(dateTime: DateTime): string {
        return dateTime.from + '-' + dateTime.to;
    }

    static simpleTodayString() {
        return new Date().toISOString().split('T')[0];
    }

    static todayDateWithoutTime(): Date {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }
}