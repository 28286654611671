import React, {useState} from "react";
import ProfilMenuButton from "../profil-menu-button/ProfilMenuButton";
import GemeindeAddressEdit from "./gemeinde-address/GemeindeAddressEdit";
import GemeindeInfoEdit from "./gemeinde-info/GemeindeInfoEdit";
import GemeindeAboutEdit from "./gemeinde-about/GemeindeAboutEdit";
import GemeindeProfileEdit from "./gemeinde-profile/GemeindeProfileEdit";

const GemeindeProfilMenu = () => {

    const [showEditProfile, setShowEditProfile] = useState(false);
    const [showAddressEdit, setShowAddressEdit] = useState(false);
    const [showInfoEdit, setShowInfoEdit] = useState(false);
    const [modalAboutOpen, setModalAboutOpen] = useState(false);

    return (
        <>
            <div className='me-flex-column me-scrollable'>
                <ProfilMenuButton
                    label={'Profil'} icon={'contact-card'}
                    onClick={() => setShowEditProfile(true)}/>
                <ProfilMenuButton
                    label={'Adresse'} icon={'house-flag'}
                    onClick={() => setShowAddressEdit(true)}/>
                <ProfilMenuButton
                    label={'Über uns'} icon={'images'}
                    onClick={() => setModalAboutOpen(true)}/>
                <ProfilMenuButton
                    label={'Informationen'} icon={'circle-info'}
                    onClick={() => setShowInfoEdit(true)}/>
            </div>
            {showEditProfile && <GemeindeProfileEdit
                onSaved={() => setShowEditProfile(false)}
                onCancel={() => setShowEditProfile(false)}/>
            }
            {showAddressEdit && <GemeindeAddressEdit
                onSaved={() => setShowAddressEdit(false)}
                onCancel={() => setShowAddressEdit(false)}/>
            }
            {showInfoEdit && <GemeindeInfoEdit
                onSaved={() => setShowInfoEdit(false)}
                onCancel={() => setShowInfoEdit(false)}/>
            }
            {modalAboutOpen && <GemeindeAboutEdit
                onSave={() => setModalAboutOpen(false)}
                onCancel={() => setModalAboutOpen(false)}/>
            }
        </>
    )

}

export default GemeindeProfilMenu;