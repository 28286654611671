import './SurveyTile.scss';

import React, {useState} from "react";
import {Card} from "react-bootstrap";
import {DateUtil} from "../../../data/DateUtil";
import Spinner from "../../../ui/spinner/Spinner";
import IconButton from "../../../ui/icon-button/IconButton";
import {Survey, SurveyState} from "../../../data/survey/Survey";
import {SurveyService} from "../../../data/survey/Survey.service";
import OpenModalButton from "../../../ui/open-model-button/open-modal-button";
import SurveyPreview from "../preview/SurveyPreview";
import SurveyResult from "../result/SurveyResult";
import { AxiosError } from 'axios';


export interface SurveyTileProps {
    survey: Survey;
    onEdit: (string: string) => void;
    onDeleted: () => void;
    onActivated: () => void;
    onError: (errorCode: number) => void;
}

const SurveyTile = (props: SurveyTileProps) => {

    const [activating, setActivating] = useState(false);
    const survey = props.survey;
    const isFinished = !DateUtil.isAfterNow(survey.enddate);

    function deleteSurvey(e: any) {
        e.stopPropagation();
        SurveyService.deleteSurvey(survey.companyId, survey.surveyId)
            .then(() => {
                props.onDeleted();
            })
    }

    function activateSurvey(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        setActivating(true);
        SurveyService.activate(survey.companyId, survey.surveyId)
            .then(props.onActivated)
            .catch((response: AxiosError) => {
                props.onError(response.response?.status as number);
            })
    }

    return (
        <Card className={'survey-card'}>
            <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'me-flex-row me-flex-grow'}>
                    <div className={'me-flex-column me-flex-grow'}>
                        {survey.intro && <Card.Title>{survey.intro.title}</Card.Title>}
                        {!survey.intro && <Card.Title>{survey.questions[0].question}</Card.Title>}
                        {survey.intro && <Card.Text>{survey.intro.description}</Card.Text>}
                        {<Card.Text> Gültig bis: {DateUtil.displayShortNoTime(survey.enddate)}</Card.Text>}
                    </div>
                </div>
                {activating && <Spinner small={true}></Spinner>}
                {!activating && <div className='me-survey-state-container'>
                    {survey.state === SurveyState.CREATED &&
                        <div className='me-activate-survey-button'
                             onClick={activateSurvey}>Veröffentlichen</div>}
                    {survey.state === SurveyState.ACTIVATED && !isFinished &&
                        <div className='me-survey-state active'>Veröffentlicht</div>}
                    {isFinished &&
                        <div className='me-survey-state active'>Ergebnis vorhanden</div>}
                </div>}
                <div className={'me-survey-card-acions'}>
                    <div className={'me-survey-card-acions-left'}>
                        <OpenModalButton
                            icon={'play'}
                            buttonLabel={'Vorschau'}
                            buttonMarginRight={10}
                            modalSize={'xl'}
                            modalTitle={'Umfrage Vorschau'}>
                            <SurveyPreview survey={survey}/>
                        </OpenModalButton>
                        <OpenModalButton
                            icon={'poll'}
                            cssSelector={''}
                            buttonMarginRight={10}
                            buttonLabel={isFinished ? 'Ergebnis' : 'Trend'}
                            modalTitle={isFinished ? 'Ergebnis' : 'Trend'}>
                            <SurveyResult
                                companyId={survey.companyId}
                                surveyId={survey.surveyId}
                            />
                        </OpenModalButton>
                    </div>
                    <div className={'me-survey-card-acions-right'}>
                        {survey.state === SurveyState.CREATED &&
                            <IconButton iconName={'edit'} onClick={() => props.onEdit(survey.surveyId)}/>}
                        {survey.state === SurveyState.CREATED &&
                            <IconButton className={'red-hover'} iconName={'trash-can'} marginLeft={10}
                                        onClick={deleteSurvey}/>}
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default SurveyTile;