import './EditCompanyAboutItemRow.scss';

import React from "react";
import Image from "react-bootstrap/Image";
import IconButton from "../../../../ui/icon-button/IconButton";
import {CompanyAboutItem} from "../../../../data/company/about/CompanyAbout";

export interface EditCompanyAboutItemRowProps {
    item: CompanyAboutItem
    number: number;
    onRemove: (item: CompanyAboutItem) => void;
    onMoveUp: (item: CompanyAboutItem) => void;
    onClick: (item: CompanyAboutItem) => void;
}

const EditCompanyAboutItemRow = (props: EditCompanyAboutItemRowProps) => {

    const item = props.item;

    function onRemove(e: any) {
        e.stopPropagation();
        props.onRemove(props.item);
    }

    function onMoveUp(e: any) {
        e.stopPropagation();
        props.onMoveUp(props.item);
    }

    return <>
        <div className={'me-edit-company-about-item-row'} onClick={() => props.onClick(props.item)}>
            <Image src={item.image} alt=''/>
            <div className={'me-edit-company-about-item-number'}>{props.number}</div>
            {item.title && <div className={'me-edit-company-about-item-title'}>{item.title}</div>}
            <div className={'me-edit-company-about-item-row-actions'}>
                <IconButton iconName={'arrow-up'} onClick={onMoveUp}/>
                <IconButton iconName={'trash-can'} className={'red-hover'} onClick={onRemove}/>
            </div>
        </div>
    </>

}

export default EditCompanyAboutItemRow;