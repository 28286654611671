export interface SendInitPaymentData {
    companyId : string;
    tokenAmount : number;
    paymentUserStreet : string;
    paymentUserStreetNumber : string;
    paymentUserZIP : string;
    paymentUserCity : string;
    paymentUserName : string;
    paymentUserSurname : string;
    paymentUserAGBAccepted : boolean;
}

export interface SendAssertPaymentData {
    companyId : string;
    requestId : string;
}

export interface SendAssertOpenPaymentsData{
    companyId : string;
}

export interface Payment{
    companyId : string;
    requestId : string;
    requestedTokenAmount : number;
    status : string;
}

export interface AssertOpenPayments{
    successfullPayments : Payment[];
    failedPayments : Payment[];
    expiredPayments : Payment[];
}

export interface PaymentHistory{
    payments : Payment[];
}

export class MeTokenPrice{

    constructor(TokenAmount : number, Price : number){
        this.TokenAmount = TokenAmount;
        this.Price = Price;
    }

    public TokenAmount: number = 0;
    public Price : number = 0;
}

export class MeTokenValidAmounts{

    public static ValidAmounts : MeTokenPrice[] = [
        new MeTokenPrice(20, 1000), 
        new MeTokenPrice(50, 2000),
        new MeTokenPrice(100, 3500)
    ];

    public static getPriceForAmount(TokenAmount : number) : number{
        const tokenPrice : number = -1;

        for(let tCount = 0; tCount < MeTokenValidAmounts.ValidAmounts.length; tCount++){
            if(MeTokenValidAmounts.ValidAmounts[tCount].TokenAmount == TokenAmount){
                return MeTokenValidAmounts.ValidAmounts[tCount].Price;
            }
        }

        return tokenPrice;
    }
}

export class MeServiceTokenPrices{
    public static EVENT_PRICE : number = 8;
    public static SURVEY_PRICE : number = 10;
    public static NOTIFICATION_PRICE : number = 3;

    public static getPriceByServiceType(serviceType : string){
        
        if(serviceType == "EVENT"){
            return this.EVENT_PRICE;
        }

        if(serviceType == "NOTIFICATION"){
            return this.NOTIFICATION_PRICE;
        }

        if(serviceType == "SURVEY"){
            return this.SURVEY_PRICE;
        }

        return -1;
    }
}