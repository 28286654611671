import {createSlice} from "@reduxjs/toolkit";

export interface SubscriptionState {
    dashboardRerfreshDate: string;
}

const initialState: SubscriptionState = {
    dashboardRerfreshDate: new Date().toISOString()
}

const applicationSlice = createSlice({
    name: 'application',
    initialState: initialState,
    reducers: {
        refreshDashboard(state) {
            state.dashboardRerfreshDate = new Date().toISOString();
        }
    }
})

export const applicationActions = applicationSlice.actions;
export default applicationSlice.reducer;